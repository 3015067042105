import React from "react";
import { TextField, Grid, Checkbox, FormControlLabel } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

import { DATES_VALUE } from "../../../../../../constants";
import { SelectByDateWithCustomPeriod } from "../../../../../../components/ui";
import { ICountriesToShowOption, ITypePurchase } from "../../utils";
import { IPlanOption } from "../../../../../../components/ui/DiscountsModal/PlansOptions";
import { SalesByPeriodPerCountry } from "../../../../../../api/billing/index.types";
import useStyles from "../../useStyles";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface IProps {
  getReport: (startDate: string, endDate: string, isAllTime?: boolean) => Promise<void>;
  setIsLoading: (isLoading: boolean) => void;
  setData: (data: SalesByPeriodPerCountry[]) => void;
  setCountriesToShow: (c: ICountriesToShowOption[]) => void;
  setPlanToShow: (p: IPlanOption[]) => void;
  planToShow: IPlanOption[];
  plansOptions: IPlanOption[];
  countriesToShow: ICountriesToShowOption[];
  salesPerCountry: Record<string, number>;
  purchaseType: ITypePurchase;
  setPurchaseType: (p: ITypePurchase) => void;
}
const Filters: React.FC<IProps> = ({
  getReport,
  setIsLoading,
  setData,
  setCountriesToShow,
  setPlanToShow,
  planToShow,
  plansOptions,
  countriesToShow,
  salesPerCountry,
  purchaseType,
  setPurchaseType,
}) => {
  const classes = useStyles();

  const handleChangeSelectedCountry = (
    _: React.ChangeEvent<unknown>,
    newValue: ICountriesToShowOption[]
  ) => setCountriesToShow(newValue);

  const handleChangePlan = (_: React.ChangeEvent<unknown>, newValue: IPlanOption[]) =>
    setPlanToShow(newValue);

  const handleChangePurchaseType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPurchaseType({ ...purchaseType, [event.target.name]: event.target.checked });
  };

  const countriesOptions = Object.keys(salesPerCountry)
    .sort((a, b) => a.localeCompare(b))
    .map((c) => ({
      value: c,
      label: c,
    }));
  console.log("countriesOptions", countriesOptions);
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <SelectByDateWithCustomPeriod
            getReport={getReport}
            setIsLoading={setIsLoading}
            setReport={setData}
            initialDateBy={DATES_VALUE.thisWeek}
          />
        </Grid>
        <Grid item xs={6}>
          <div className={classes.filtersByPurchaseTypeContainer}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={purchaseType.isShowPurchase}
                  onChange={handleChangePurchaseType}
                  name="isShowPurchase"
                  color="primary"
                />
              }
              label="Show new purchases"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={purchaseType.isShowRebill}
                  onChange={handleChangePurchaseType}
                  name="isShowRebill"
                  color="primary"
                />
              }
              label="Show rebils"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={purchaseType.isShowResets}
                  onChange={handleChangePurchaseType}
                  name="isShowResets"
                  color="primary"
                />
              }
              label="Show resets"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={purchaseType.isShowFreeResets}
                  onChange={handleChangePurchaseType}
                  name="isShowFreeResets"
                  color="primary"
                />
              }
              label="Show free resets"
            />
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Autocomplete
            multiple
            disableCloseOnSelect
            onChange={handleChangePlan}
            fullWidth
            value={planToShow}
            options={plansOptions}
            getOptionLabel={(option) => option.label}
            getOptionSelected={(option, value) => option.value === value.value}
            renderInput={(params) => (
              <TextField {...params} variant="standard" label="Plans" placeholder="Plans" />
            )}
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.label}
              </React.Fragment>
            )}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Autocomplete
            disableCloseOnSelect
            onChange={handleChangeSelectedCountry}
            fullWidth
            multiple
            value={countriesToShow}
            getOptionSelected={(option, value) => option.value === value.value}
            getOptionLabel={(option) => option.value}
            options={countriesOptions}
            renderInput={(params) => (
              <TextField {...params} variant="standard" label="Countries" placeholder="Countries" />
            )}
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.label}
              </React.Fragment>
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Filters;
