import React from "react";

import { IColum } from "../../../../utils/copyToClipboardInXlsFormat";
import { IntlFormatUSD } from "../../../../utils";
import { CopyToClipboard } from "../../../../components/ui";

export const COLUMNS: Array<IColum> = [
  {
    by: "name",
    title: "Name",
    path: "name",
    getCellValue: (value) => <CopyToClipboard value={value as unknown as string} />,
  },
  {
    by: "amount",
    title: "Amount",
    path: "amount",
    getCellValue: (value) => <CopyToClipboard value={IntlFormatUSD(value as unknown as number)} />,
  },
];
