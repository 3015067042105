import React, { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";

import Table from "../ComplianceSearch/components/Table";
import { COLUMNS } from "./constants";
import useStyles from "../ComplianceSearch/useStyles";
import usePagination from "../../hooks/usePagination";
import { FilterAutocomplete } from "../../components/ui";
import { ComplianceSearchActions } from "../../reducers/ComplianceSearchReducer/actions";
import { useComplianceSearchContext } from "../../context";
import { COMPLIANCE_API_URLS, ComplianceAPI } from "../../api/compliance";
import { appendSearchParamsToQuery, TitleUpdated } from "../ComplianceSearch/utils";

const HeliosNotesSearch: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const pagination = usePagination();

  const { tabsTablesSearchState, setTabsTablesSearchState } = useComplianceSearchContext();
  const { search } = tabsTablesSearchState.watchListPnlTab.heliosNotesSearchTable;

  const {
    loading,
    data: { results, updated },
  } = useSelector((state) => state.complianceSearch.watchlistNotes);

  useQuery({
    retry: false,
    queryKey: [
      COMPLIANCE_API_URLS.getSearchNotes,
      Object.values(search),
      pagination.page,
      pagination.limit,
    ],
    async queryFn({ signal }) {
      const query = new URLSearchParams();
      query.append("limit", pagination.limit.toString());
      query.append("page", pagination.page.toString());
      appendSearchParamsToQuery(search, query);

      dispatch(ComplianceSearchActions.fetchWatchlistNotesRequest());
      const response = await ComplianceAPI.getSearchNotes(query.toString(), signal);
      pagination.setCount(response.data.results.count);
      dispatch(ComplianceSearchActions.fetchWatchlistNotesSuccess(response.data));
      return response;
    },
    onError(error) {
      console.error("Error fetching helios notes search", error);
      dispatch(ComplianceSearchActions.fetchWatchlistNotesFailed());
    },
  });

  const handleChangePage = (page: number) => pagination.setPage(page);

  useEffect(() => {
    pagination.setPage(0);
  }, [search, pagination.limit]);

  return (
    <div>
      <b className={classes.title}>Watchlist notes</b>

      <div>
        <FilterAutocomplete
          search={search}
          setSearch={(values) => {
            setTabsTablesSearchState({
              ...tabsTablesSearchState,
              watchListPnlTab: {
                ...tabsTablesSearchState.watchListPnlTab,
                heliosNotesSearchTable: {
                  search: values,
                },
              },
            });
          }}
          filters={{
            Name: "names",
            Email: "email",
          }}
        />
      </div>

      <Table
        columns={COLUMNS}
        title={<TitleUpdated value={updated?.measure_timestamp} />}
        loading={loading}
        pagination={pagination}
        handleChangePage={handleChangePage}
        extractKey={(row) => row.id}
        data={results.rows}
        csv_table_name="Wathclist, Pnl - Watchlist notes"
      />
    </div>
  );
};

export default HeliosNotesSearch;
