import httpClient from "../apiClient";
import * as Type from "./index.types";

export const COMPLIANCE_API_URLS = {
  getNotes: "compliance/watchlist/notes",
  getAllNotes: "compliance/watchlist/notes/all",
  getEntries: "compliance/search-entities",
  getCustomersLogins: "compliance/search/logins",
  getUniqueReqIp: "compliance/search/unique-req-ip",
  getPaymentCards: "compliance/search/payment-cards",
  getAccountPlans: "compliance/search/account-plans",
  getAccountPlansStatus: "compliance/search/account-plans-status",
  getAccountsPurchases: "compliance/search/account-purchases",
  getAccountsResetsRebillsNewPurchases: "compliance/search/account-resets-rebills-new-purchases",
  getBasicAccountDetailsAndSalesValue: "compliance/search/account-basic-details-and-sales-value",
  getEntriesByName: "compliance/search/search-entities-by-values",
  getEvaluationsRithmicAccounts: "compliance/search/evaluations-rithmic-accounts",
  // watchlist routes
  getPasses: "compliance/search/watchlist/passes",
  getWithdrawals: "compliance/search/watchlist/withdrawals",
  getFailed: "compliance/search/watchlist/failed",
  getLiveSimTif: "compliance/search/watchlist/live-sim-tif",
  getHeliosLiveTif: "compliance/search/watchlist/helios-live-tif",
  getAppiusLiveTif: "compliance/search/watchlist/appius-live-tif",
  getSearchNotes: "compliance/search/watchlist/notes",
  fundedAccountInvestigation: "compliance/search/watchlist/funded-account-investigation",
  getPnlData: "compliance/search/watchlist/pnl-data",
  getWatchlistHeliosAppiusLiveTif: "compliance/search/watchlist/helios-appius-live-tif",
  getTopLiveSimTakers: "compliance/search/watchlist/live-sim-takers-top",
  getTopLiveLosers: "compliance/search/watchlist/live-losses-top",
} as const;

export class ComplianceAPI {
  static getCustomerComplianceNotesRequest: Type.TGetCustomerComplianceNotes = (query, signal) =>
    httpClient.get(`${COMPLIANCE_API_URLS.getNotes}?${query}`, { signal });
  static getAllComplianceNotesRequest: Type.TGetAllComplianceNotesRequest = (query, signal) =>
    httpClient.get(`${COMPLIANCE_API_URLS.getAllNotes}?${query}`, { signal });
  static getAllEntriesRequest: Type.TGetAllEntriesRequest = (body, signal) =>
    httpClient.post(COMPLIANCE_API_URLS.getEntries, body, { signal });
  static getCustomersLoginsRequest: Type.TGetCustomersLoginsRequest = (query: string, signal) =>
    httpClient.get(`${COMPLIANCE_API_URLS.getCustomersLogins}?${query}`, { signal });
  static getUniqueReqIpRequest: Type.TGetUniqueReqIpRequest = (query: string, signal) =>
    httpClient.get(`${COMPLIANCE_API_URLS.getUniqueReqIp}?${query}`, { signal });
  static getPaymentCardsRequest: Type.TGetPaymentCardsRequest = (query: string, signal) =>
    httpClient.get(`${COMPLIANCE_API_URLS.getPaymentCards}?${query}`, { signal });
  static getAccountPlans: Type.TGetAccountPlans = (query: string) =>
    httpClient.get(`${COMPLIANCE_API_URLS.getAccountPlans}?${query}`);
  static getAccountPlansStatus: Type.TGetAccountPlansStatus = (query: string) =>
    httpClient.get(`${COMPLIANCE_API_URLS.getAccountPlansStatus}?${query}`);
  static getAccountsPurchases: Type.TGetAccountsPurchases = (query: string) =>
    httpClient.get(`${COMPLIANCE_API_URLS.getAccountsPurchases}?${query}`);
  static getAccountsResetsRebillsNewPurchases: Type.TGetAccountsResetsRebillsNewPurchases = (
    query: string
  ) => httpClient.get(`${COMPLIANCE_API_URLS.getAccountsResetsRebillsNewPurchases}?${query}`);
  static getBasicAccountDetailsAndSalesValue: Type.TGetBasicAccountDetailsAndSalesValue = (query) =>
    httpClient.get(`${COMPLIANCE_API_URLS.getBasicAccountDetailsAndSalesValue}?${query}`);
  static getAllEntriesRequestByValues: Type.TGetAllEntriesRequestByValuesRequest = (query) =>
    httpClient.get(`${COMPLIANCE_API_URLS.getEntriesByName}?${query}`);
  static getEvaluationsRithmicAccountsRequest: Type.TGetEvaluationsRithmicAccountsRequest = (
    query,
    signal
  ) => httpClient.get(`${COMPLIANCE_API_URLS.getEvaluationsRithmicAccounts}?${query}`, { signal });
  // watchlist routes
  static getWatchlistPassesRequest: Type.TGetPassesRequest = (query, signal) =>
    httpClient.get(`${COMPLIANCE_API_URLS.getPasses}?${query}`, { signal });
  static getWatchlistWithdrawalsRequest: Type.TGetWithdrawalsRequest = (query, signal) =>
    httpClient.get(`${COMPLIANCE_API_URLS.getWithdrawals}?${query}`, { signal });
  static getWatchlistFailedRequest: Type.TGetWatchlistFailedRequest = (query, signal) =>
    httpClient.get(`${COMPLIANCE_API_URLS.getFailed}?${query}`, { signal });
  static getWatchlistLiveSimTif: Type.TGetWatchlistLiveSimTifRequest = (query, signal) =>
    httpClient.get(`${COMPLIANCE_API_URLS.getLiveSimTif}?${query}`, { signal });
  static getWatchlistLiveTif: Type.TGetWatchlistLiveTifRequest = (query, signal) =>
    httpClient.get(`${COMPLIANCE_API_URLS.getHeliosLiveTif}?${query}`, { signal });
  static getWatchlistAppiusLiveTif: Type.TGetWatchlistAppiusLiveTifRequest = (query, signal) =>
    httpClient.get(`${COMPLIANCE_API_URLS.getAppiusLiveTif}?${query}`, { signal });
  static getSearchNotes: Type.TGetSearchNotesRequest = (query, signal) =>
    httpClient.get(`${COMPLIANCE_API_URLS.getSearchNotes}?${query}`, { signal });
  static getFundedAccountInvestigationRequest: Type.TGetHeliosRelatedSearchRequest = (
    query,
    signal
  ) => httpClient.get(`${COMPLIANCE_API_URLS.fundedAccountInvestigation}?${query}`, { signal });
  static getPnlDataRequest: Type.TGetPnlDataRequest = (query, signal) =>
    httpClient.get(`${COMPLIANCE_API_URLS.getPnlData}?${query}`, { signal });
  static getWatchlistHeliosAppiusLiveTifRequest: Type.TGetWatchlistHeliosAppiusLiveTifRequest = (
    query,
    signal
  ) =>
    httpClient.get(`${COMPLIANCE_API_URLS.getWatchlistHeliosAppiusLiveTif}?${query}`, { signal });
  static getTopLiveSimTakersRequest: Type.TGetTopLiveLosersAndTopLiveSimTakersRequest = (signal) =>
    httpClient.get(`${COMPLIANCE_API_URLS.getTopLiveSimTakers}`, {
      signal,
    });
  static getTopLiveLosersRequest: Type.TGetTopLiveLosersRequest = (signal) =>
    httpClient.get(`${COMPLIANCE_API_URLS.getTopLiveLosers}`, {
      signal,
    });
}
