import React from "react";

import { IColum } from "../../../../utils/copyToClipboardInXlsFormat";
import { IntlFormatUSD } from "../../../../utils";
import { CopyToClipboard } from "../../../../components/ui";

export const COLUMNS: Array<IColum> = [
  {
    by: "names",
    title: "Name",
    path: "names",
    getCellValue: (value: unknown): React.ReactElement => {
      const stringValue = value as unknown as string;
      return (
        <>
          {stringValue?.split(", ").map((v) => (
            <CopyToClipboard key={v} value={v} />
          ))}
        </>
      );
    },
  },
  {
    by: "measure_taken",
    title: "Measure Taken",
    path: "measure_taken",
    getCellValue: (value: unknown): React.ReactElement => {
      const stringValue = value as unknown as string;
      return (
        <>
          {stringValue?.split("|| ").map((v) => (
            <span
              style={{
                display: "block",
                // @ts-ignore
                textWrap: "auto",
              }}
              key={v}
            >
              {v}
            </span>
          ))}
        </>
      );
    },
  },
  {
    by: "measure_timestamp",
    title: "Timestamp",
    path: "measure_timestamp",
    getCellValue: (value: unknown): React.ReactElement => {
      const stringValue = value as unknown as string;
      return (
        <>
          {stringValue?.split("|| ").map((v) => (
            <>
              {stringValue?.split("|| ").map((v) => (
                <span
                  style={{
                    display: "block",
                    // @ts-ignore
                    textWrap: "auto",
                  }}
                  key={v}
                >
                  {v}
                </span>
              ))}
            </>
          ))}
        </>
      );
    },
  },
  {
    by: "w80_sum",
    title: "Sum",
    path: "w80_sum",
    getCellValue: (value) => <CopyToClipboard value={IntlFormatUSD(value as unknown as number)} />,
  },
];
