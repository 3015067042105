import React from "react";
import * as Yup from "yup";
import { INewTradedAccountForm } from "./components/TradedAccounts";
import { FundedTraderActions } from "../../reducers/fundedTraderReducer/actions";
import { FundedTraderAction } from "../../reducers/fundedTraderReducer/index.types";
import { APIFunds } from "../../api";
import { EPProcessingCompletedStatuses, EProcessingStatuses } from "../../constants";
import { ITradedAccount } from "../../api/funds/index.types";
import { ISelectOption } from "../../components/ui/FormSelect";
export const ACCOUNTS_SCHEMA = Yup.object().shape({
  e2t_account_id: Yup.string().required("Account ID is required").min(3),
  processing_status: Yup.string().required("Processing status is required"),
  invoice_status: Yup.string().required("Invoice status is required"),
  evaluation_completed_history_id: Yup.number()
    .min(1, "E2T account name is required")
    .required("E2T account name is required"),
});

export const getAccountsInitialValues = (customerId: number): INewTradedAccountForm => ({
  e2t_account_id: "",
  prop_firm: "",
  account_type: "",
  processing_status: "",
  accounts_id: customerId,
  funded_traders_id: customerId,
  account_size: "",
  invoice_status: "",
  evaluation_completed_history_id: 0,
  initial_tcp_account_id: "",
});

export const fetchTradedAccounts = async (
  dispatch: React.Dispatch<FundedTraderAction>,
  customerId: number
): Promise<void> => {
  try {
    dispatch(FundedTraderActions.fetchFundedTraderAccountsRequest());
    const response = await APIFunds.getTradedAccountsRequest(customerId);
    dispatch(FundedTraderActions.fetchFundedTraderAccountsSuccess(response));
  } catch (error) {
    console.error("Error getting traded accounts", error);
  } finally {
    dispatch(FundedTraderActions.fetchFundedTraderAccountsFailed());
  }
};

type ICheckIsCompletedAccount = (
  processing_status: EProcessingStatuses,
  account_size?: string | null,
  account?: ITradedAccount,
  tradedAccounts?: ITradedAccount[]
) => boolean;
export const checkIsCompletedAccount: ICheckIsCompletedAccount = (
  processing_status,
  account_size,
  account,
  tradedAccounts
): boolean => {
  if (account_size?.includes("TCP") && account?.id && tradedAccounts?.length) {
    const subAccounts: ITradedAccount[] = [];

    if (account?.initial_tcp_account_id) {
      const accounts = tradedAccounts.filter(
        (ta) =>
          ta.initial_tcp_account_id === account?.initial_tcp_account_id ||
          ta.id === account?.initial_tcp_account_id
      );
      subAccounts.push(...accounts);
    } else {
      const accounts = tradedAccounts.filter((ta) => ta.initial_tcp_account_id === account.id);
      subAccounts.push(account, ...accounts);
    }

    const allExceptLatest = subAccounts.slice(0, -1);
    const latestAccount = subAccounts.at(-1);

    return (
      latestAccount?.processing_status === EProcessingStatuses.failed &&
      allExceptLatest.every(
        ({ processing_status }) => processing_status === EProcessingStatuses.completed
      )
    );
  }
  if (account_size?.includes("GM") && processing_status === EProcessingStatuses.completed) {
    return true;
  }
  return EPProcessingCompletedStatuses.includes(processing_status);
};

export const getTradedAccountsOptions = (tradedAccountsData: ITradedAccount[]): ISelectOption[] => {
  const options = [
    {
      value: "",
      label: "No",
      id: -1,
    },
  ];
  const tradedAccounts = tradedAccountsData
    .filter(
      (ta) =>
        !checkIsCompletedAccount(ta.processing_status, ta.account_size, ta, tradedAccountsData)
    )
    .filter(
      ({ account_size, processing_status, initial_tcp_account_id }) =>
        account_size?.includes("TCP") &&
        processing_status !== EProcessingStatuses.failed &&
        !initial_tcp_account_id
    )
    .map((account) => ({
      value: account.id,
      label: account.e2t_account_id,
      id: account.id,
    }));

  return [...options, ...tradedAccounts];
};

export const MAXIMUM_FUNDED_ACCOUNTS_COUNT = 3;
