import React from "react";
import { useDispatch, useSelector } from "react-redux";

import Table from "../../Table";
import useStyles from "../../../useStyles";
import { IColum } from "../../../../../utils/copyToClipboardInXlsFormat";
import { ComplianceAPI } from "../../../../../api/compliance";
import SearchByListValues from "../../SearchByListValues";
import { ComplianceSearchActions } from "../../../../../reducers/ComplianceSearchReducer/actions";
import { getQueryStringByStringValues } from "../../../utils";
import { EMAILS_LIST_VALIDATION_SCHEMA } from "../../../../../utils/validation";
import { useComplianceSearchContext } from "../../../../../context";

const COLUMNS: Array<IColum> = [
  {
    by: "accounts_id",
    title: "Account ID ",
    path: "account.id",
  },
  {
    by: "email",
    title: "Email",
    path: "account.email",
  },
  {
    by: "name",
    title: "Name",
    path: "account.name",
  },
  {
    by: "id",
    title: "ID",
    path: "id",
  },
  {
    by: "plans_id",
    title: "Plans ID",
    path: "plans_id",
  },
  {
    by: "price_usd",
    title: "Price USD",
    path: "price_usd",
  },
  {
    by: "expires",
    title: "Expires",
    path: "expires",
  },
  {
    by: "falitures",
    title: "Failures",
    path: "falitures",
  },
  {
    by: "status",
    title: "Status",
    path: "status",
  },
  {
    by: "passed",
    title: "Passed",
    path: "passed",
    copyToClipBoardCallBack: (value) => (value ? "passed" : ""),
  },
  {
    by: "start",
    title: "Start",
    path: "start",
  },
  {
    by: "canceled",
    title: "Canceled",
    path: "canceled",
  },
  {
    by: "campaign_property_id",
    title: "Campaign property id",
    path: "campaign_property_id",
  },
  {
    by: "discount_code_id",
    title: "Discount code id",
    path: "discount_code_id",
  },
  {
    by: "upgrade",
    title: "Upgrade",
    path: "id",
    copyToClipBoardCallBack: (value) => (value ? "true" : "false"),
  },
  {
    by: "free_resets",
    title: "Free resets",
    path: "free_resets",
  },
  {
    by: "orig_discount_id",
    title: "Orig discount id",
    path: "orig_discount_id",
  },
];

const SubscriptionStatusTable = (): React.ReactElement => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { data, loading } = useSelector((state) => state.complianceSearch.plansStatus);
  const { tabsTablesSearchState, setTabsTablesSearchState } = useComplianceSearchContext();
  const { emails } = tabsTablesSearchState.quickSearchTab.subscriptionStatusTable.search;

  const fetchData = async (values: { emails: string }) => {
    setTabsTablesSearchState({
      ...tabsTablesSearchState,
      quickSearchTab: {
        ...tabsTablesSearchState.quickSearchTab,
        subscriptionStatusTable: {
          search: { emails: values.emails },
        },
      },
    });

    try {
      dispatch(ComplianceSearchActions.fetchComplianceSearchPlansStatusRequest());
      const response = await ComplianceAPI.getAccountPlansStatus(
        getQueryStringByStringValues("emails", values.emails)
      );
      dispatch(
        ComplianceSearchActions.fetchComplianceSearchPlansStatusSuccess(response.data.accounts)
      );
    } catch (error) {
      dispatch(ComplianceSearchActions.fetchComplianceSearchPlansStatusFailed());
      console.log("error fetching SubscriptionStatusTable  data", error);
    }
  };

  return (
    <div>
      <b className={classes.title}>Search active/canceled subscriptions</b>
      <SearchByListValues
        validationSchema={EMAILS_LIST_VALIDATION_SCHEMA}
        isLoading={loading}
        fetchData={fetchData}
        placeholder="john@gmail.com, doe@gmail.com e.t.c ..."
        label="Emails"
        name="emails"
        initialValues={{ emails }}
      />

      <Table
        loading={loading}
        columns={COLUMNS}
        extractKey={(row) => row.id}
        data={data}
        csv_table_name="E2T quick search - Search active/canceled subscriptions"
      />
    </div>
  );
};

export default SubscriptionStatusTable;
