import React from "react";
import moment from "moment";
import cx from "classnames";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { List, ListItem, ListItemText, MenuItem } from "@material-ui/core";

import useStyles from "./useStyles";
import { DATE_FORMAT } from "../../../../../../constants";
import { CopyToClipboard } from "../../../../../../components/ui";

const TableBody = (): React.ReactElement => {
  const classes = useStyles();
  const { data } = useSelector((state) => state.suspiciousCustomers);

  return (
    <tbody>
      {data.map(({ id, name, email, suspicious_accounts_notes, banned_account }) => {
        return (
          <tr key={id} className={cx({ [classes.banned]: banned_account })}>
            <td>{id}</td>
            <td>
              <MenuItem component={Link} to={`/customer/${id}`}>
                {name}
              </MenuItem>
            </td>
            <td>
              <CopyToClipboard value={email} />
            </td>
            <td>
              <List>
                {banned_account && (
                  <ListItemText>
                    Banned on: {moment(banned_account.timestamp).format(DATE_FORMAT)}
                  </ListItemText>
                )}
                {suspicious_accounts_notes.map(({ id, note }) => (
                  <ListItem className={classes.listItem} key={id}>
                    <ListItemText>- {note}</ListItemText>
                  </ListItem>
                ))}
              </List>
            </td>
          </tr>
        );
      })}
    </tbody>
  );
};

export default TableBody;
