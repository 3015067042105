import React, { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { uniqueId } from "lodash";

import useStyles from "../../useStyles";
import { COMPLIANCE_API_URLS, ComplianceAPI } from "../../../../api/compliance";
import { ComplianceSearchActions } from "../../../../reducers/ComplianceSearchReducer/actions";
import { COLUMNS } from "./constants";
import Table from "../Table";

import usePagination from "../../../../hooks/usePagination";
import { TitleUpdated } from "../../utils";

const TopLiveLosers: React.FC = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const pagination = usePagination();

  const { data, loading } = useSelector((state) => state.complianceSearch.topLiveLosers);

  const [results, setResults] = useState<Array<{ name: string; amount: number }>>([]);

  useQuery({
    queryKey: [COMPLIANCE_API_URLS.getTopLiveLosers],
    async queryFn({ signal }) {
      dispatch(ComplianceSearchActions.fetchTopLiveLosersRequest());
      const response = await ComplianceAPI.getTopLiveLosersRequest(signal);

      const uniqueRecordsByFoundNames = new Map();
      response.data.liveLosers.forEach((data) => {
        let amount = 0;
        const name: string[] = [];

        data.forEach((value) => {
          name.push(value.name);
          amount += value.amount;
        });
        uniqueRecordsByFoundNames.set(name.join(", "), {
          name: name.join(", "),
          amount,
        });
      });
      const sortedDescAmountRecords = Array.from(uniqueRecordsByFoundNames.values()).sort((a, b) =>
        b.amount > a.amount ? 1 : -1
      );

      dispatch(
        ComplianceSearchActions.fetchTopLiveLosersSuccess({
          data: {
            liveLosers: sortedDescAmountRecords,
            updated: response.data.updated,
          },
        })
      );
      pagination.setCount(sortedDescAmountRecords.length);
      return response;
    },
    onError(error) {
      dispatch(ComplianceSearchActions.fetchTopLiveLosersFailed());
      console.error("Error fetching Top Live losers", error);
    },
  });

  useEffect(() => {
    const { page, limit } = pagination;
    const paginatedCharges = data.liveLosers.slice(page * +limit, page * +limit + +limit);

    setResults(paginatedCharges);
  }, [pagination.page, data, pagination.limit]);

  useEffect(() => {
    pagination.setPage(0);
  }, [pagination.limit]);

  return (
    <div>
      <b className={classes.title}>Top Live losers</b>

      <Table
        title={<TitleUpdated value={data.updated.timestamp} />}
        columns={COLUMNS}
        loading={loading}
        extractKey={(row) => `${row.name}_${uniqueId()}`}
        data={results}
        pagination={pagination}
        handleChangePage={pagination.setPage}
        csv_table_name="Top Live losses, and top LiveSim takers - Top Live losers"
      />
    </div>
  );
};

export default TopLiveLosers;
