import React from "react";
import { useDispatch, useSelector } from "react-redux";

import Table from "../../Table";
import useStyles from "../../../useStyles";
import { IColum } from "../../../../../utils/copyToClipboardInXlsFormat";
import { IntlFormatUSD } from "../../../../../utils";
import { ComplianceAPI } from "../../../../../api/compliance";
import SearchByListValues from "../../SearchByListValues";
import { CENTS_IN_DOLLAR } from "../../../../../constants";
import { ComplianceSearchActions } from "../../../../../reducers/ComplianceSearchReducer/actions";
import { getQueryStringByStringValues } from "../../../utils";
import { EMAILS_LIST_VALIDATION_SCHEMA } from "../../../../../utils/validation";
import { useComplianceSearchContext } from "../../../../../context";

const COLUMNS: Array<IColum> = [
  {
    by: "id",
    title: "Account ID",
    path: "id",
  },
  {
    by: "total_spent",
    title: "Total spent",
    path: "total_spent",
    copyToClipBoardCallBack: (value) => IntlFormatUSD(+value / CENTS_IN_DOLLAR),
    getCellValue: (value) => IntlFormatUSD(+value / CENTS_IN_DOLLAR),
  },
  {
    by: "name",
    title: "Name",
    path: "name",
  },
  {
    by: "email",
    title: "Email",
    path: "email",
  },
  {
    by: "reg_ip",
    title: "Reg IP",
    path: "reg_ip",
  },
  {
    by: "phone",
    title: "Phone",
    path: "phone",
  },
  {
    by: "address",
    title: "Address",
    path: "address",
  },
  {
    by: "address2",
    title: "Address 2",
    path: "address2",
  },
  {
    by: "city",
    title: "City",
    path: "city",
  },
  {
    by: "stateprov",
    title: "Stateprov",
    path: "stateprov",
  },
  {
    by: "country",
    title: "Country",
    path: "country",
  },
  {
    by: "joined",
    title: "Joined",
    path: "joined",
  },
];

const BasicAccountDetailsAndSalesValue = (): React.ReactElement => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { tabsTablesSearchState, setTabsTablesSearchState } = useComplianceSearchContext();
  const { emails } = tabsTablesSearchState.quickSearchTab.basicAccountDetailsAndSalesValue.search;

  const { loading, data } = useSelector(
    (state) => state.complianceSearch.basicAccountDetailsAndSalesValue
  );

  const fetchData = async (values: { emails: string }) => {
    setTabsTablesSearchState({
      ...tabsTablesSearchState,
      quickSearchTab: {
        ...tabsTablesSearchState.quickSearchTab,
        basicAccountDetailsAndSalesValue: {
          search: { emails: values.emails },
        },
      },
    });

    try {
      dispatch(
        ComplianceSearchActions.fetchComplianceSearchBasicAccountDetailsAndSalesValueRequest()
      );
      const response = await ComplianceAPI.getBasicAccountDetailsAndSalesValue(
        getQueryStringByStringValues("emails", values.emails)
      );
      dispatch(
        ComplianceSearchActions.fetchComplianceSearchBasicAccountDetailsAndSalesValueSuccess(
          response.data.accounts
        )
      );
    } catch (error) {
      dispatch(
        ComplianceSearchActions.fetchComplianceSearchBasicAccountDetailsAndSalesValueFailed()
      );
      console.log("Error fetching basic account details and sales value data", error);
    }
  };

  return (
    <div>
      <b className={classes.title}>Basic Account Details And Sales Value</b>
      <SearchByListValues
        placeholder="john@gmail.com, doe@gmail.com e.t.c ..."
        label="Emails"
        name="emails"
        validationSchema={EMAILS_LIST_VALIDATION_SCHEMA}
        isLoading={loading}
        fetchData={fetchData}
        initialValues={{ emails }}
      />

      <Table
        loading={loading}
        columns={COLUMNS}
        extractKey={(row) => row.id}
        data={data}
        csv_table_name="E2T quick search - Basic Account Details And Sales Value"
      />
    </div>
  );
};

export default BasicAccountDetailsAndSalesValue;
