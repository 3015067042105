import React from "react";

import useStyles from "../../useStyles";
import PnLCalculator from "../PnLCalculator";
import HeliosNotesSearch from "../../../HeliosNotesSearch";

const WatchListPnlTab: React.FC = () => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.tableContainer}>
        <PnLCalculator />
      </div>
      <hr />
      <div className={classes.tableContainer}>
        <HeliosNotesSearch />
      </div>
      <hr />
    </div>
  );
};

export default WatchListPnlTab;
