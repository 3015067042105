import React, { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { uniqueId } from "lodash";

import Table from "../Table";
import useStyles from "../../useStyles";
import { COLUMNS } from "./constants";
import usePagination from "../../../../hooks/usePagination";
import { ComplianceSearchActions } from "../../../../reducers/ComplianceSearchReducer/actions";
import { COMPLIANCE_API_URLS, ComplianceAPI } from "../../../../api/compliance";
import { ILiveSimTakers } from "../../../../api/compliance/index.types";
import { TitleUpdated } from "../../utils";

const TopLiveSimTakers: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const pagination = usePagination();
  const { loading, data } = useSelector((state) => state.complianceSearch.topLiveSimTakers);

  const [results, setResults] = useState<ILiveSimTakers[]>([]);

  useQuery({
    queryKey: [COMPLIANCE_API_URLS.getTopLiveSimTakers],
    async queryFn({ signal }) {
      dispatch(ComplianceSearchActions.fetchTopLiveSimTakersRequest());
      const response = await ComplianceAPI.getTopLiveSimTakersRequest(signal);
      const sorted = response.data.liveSimTakers
        .map((el) => el[0])
        .sort((a, b) => (b.w80_sum > a.w80_sum ? 1 : -1));

      dispatch(
        ComplianceSearchActions.fetchTopLiveSimTakersSuccess({
          liveSimTakers: sorted,
          updated: response.data.updated,
        })
      );

      pagination.setCount(sorted.length);
      return response;
    },
    onError(error) {
      console.error("Error fetching Top LiveSim Takers", error);
      dispatch(ComplianceSearchActions.fetchTopLiveSimTakersFailed());
    },
  });

  useEffect(() => {
    const { page, limit } = pagination;
    const paginatedCharges = data.liveSimTakers.slice(page * +limit, page * +limit + +limit);

    setResults(paginatedCharges);
  }, [pagination.page, data.liveSimTakers, pagination.limit]);

  useEffect(() => {
    pagination.setPage(0);
  }, [pagination.limit]);

  return (
    <div>
      <b className={classes.title}>Top LiveSim takers</b>

      <Table
        title={<TitleUpdated value={data.updated.timestamp} />}
        columns={COLUMNS}
        loading={loading}
        extractKey={(row) => `${row.names}_${uniqueId()}`}
        data={results}
        pagination={pagination}
        handleChangePage={pagination.setPage}
        csv_table_name={"Top Live losses, and top LiveSim takers - Top LiveSim takers"}
      />
    </div>
  );
};

export default TopLiveSimTakers;
