import React from "react";
import moment from "moment-timezone";

import { IColum } from "../../utils/copyToClipboardInXlsFormat";
import { DATE_FORMAT } from "../../constants";
import { RenderCellValue, replaceCommaAndNewLineSymbols, replaceNewLineAndTrim } from "./utils";
import { CopyToClipboard } from "../../components/ui";

export const PASSES_COLUMNS: Array<IColum> = [
  {
    by: "timestamp",
    title: "Timestamp",
    path: "timestamp",
    copyToClipBoardCallBack: (value) => moment(value).format(DATE_FORMAT),
    getCellValue: (value) => moment(value).format(DATE_FORMAT),
  },
  {
    by: "name",
    title: "Name",
    path: "name",
    getCellValue: (value) => <CopyToClipboard value={value as unknown as string} />,
  },
  {
    by: "email",
    title: "Email",
    path: "email",
    getCellValue: (value) => <CopyToClipboard value={value as unknown as string} />,
  },
  {
    by: "eval_account_name",
    title: "Passed evaluation account",
    path: "eval_account_name",
  },
  {
    by: "eval_account_size",
    title: "Account size",
    path: "eval_account_size",
  },
];

export const HELIOS_LIVE_SIM_TIF_COLUMNS: Array<IColum> = [
  {
    by: "submission_timestamp",
    title: "Submission timestamp",
    path: "submission_timestamp",
  },
  {
    by: "pw",
    title: "PW",
    path: "pw",
    getCellValue: (value) => <CopyToClipboard value={value as unknown as string} />,
    copyToClipBoardCallBack: (value) => replaceCommaAndNewLineSymbols(value),
  },
  {
    by: "name",
    title: "Name",
    path: "name",
    getCellValue: (value) => <CopyToClipboard value={value as unknown as string} />,
  },
  {
    by: "email",
    title: "Email",
    path: "email",
    getCellValue: (value) => <CopyToClipboard value={value as unknown as string} />,
  },
  {
    by: "status",
    title: "Status",
    path: "status",
  },
  {
    by: "dob",
    title: "DOB",
    path: "dob",
  },
  {
    by: "phone",
    title: "Phone",
    path: "phone",
    copyToClipBoardCallBack: (value: string): string => {
      if (value) {
        return value.replaceAll("+", "").replaceAll("\n", " ");
      }
      return "";
    },
    getCellValue: (value) => <CopyToClipboard value={value as unknown as string} />,
  },
  {
    by: "address",
    title: "Address",
    path: "address",
    copyToClipBoardCallBack: (value) => replaceCommaAndNewLineSymbols(value),
    getCellValue: (value) => <CopyToClipboard value={value as unknown as string} />,
  },
  {
    by: "account_type",
    title: "Account type",
    path: "account_type",
  },
  {
    by: "id_and_poa",
    title: "ID and POA",
    path: "id_and_poa",
    getCellValue: (value) => <RenderCellValue value={value as unknown as string} />,
    copyToClipBoardCallBack: (value) => replaceCommaAndNewLineSymbols(value),
  },
  {
    by: "tax_form",
    title: "Tax form",
    path: "tax_form",
    getCellValue: (value) => <RenderCellValue value={value as unknown as string} />,
    copyToClipBoardCallBack: (value) => replaceCommaAndNewLineSymbols(value),
  },
];

export const HELIOS_LIVE_TIF_COLUMNS: Array<IColum> = [
  {
    by: "submission_timestamp",
    title: "Submission timestamp",
    path: "submission_timestamp",
    copyToClipBoardCallBack: (value) => replaceNewLineAndTrim(value),
  },
  {
    by: "name",
    title: "Name",
    path: "name",
    copyToClipBoardCallBack: (value) => replaceNewLineAndTrim(value),
    getCellValue: (value) => <CopyToClipboard value={value as unknown as string} />,
  },
  {
    by: "email",
    title: "Email",
    path: "email",
    copyToClipBoardCallBack: (value) => replaceNewLineAndTrim(value),
    getCellValue: (value) => <CopyToClipboard value={value as unknown as string} />,
  },
  {
    by: "status",
    title: "Status",
    path: "status",
    copyToClipBoardCallBack: (value) => replaceNewLineAndTrim(value),
  },
  {
    by: "dob",
    title: "DOB",
    path: "dob",
    copyToClipBoardCallBack: (value) => replaceNewLineAndTrim(value),
  },
  {
    by: "phone",
    title: "Phone",
    path: "phone",
    copyToClipBoardCallBack: (value: string): string => {
      if (value) {
        return value.replaceAll("+", "").replaceAll("\n", " ");
      }
      return "";
    },
    getCellValue: (value) => <CopyToClipboard value={value as unknown as string} />,
  },
  {
    by: "address",
    title: "Address",
    path: "address",
    copyToClipBoardCallBack: (value) => replaceCommaAndNewLineSymbols(value),
    getCellValue: (value) => <CopyToClipboard value={value as unknown as string} />,
  },
  {
    by: "account_type",
    title: "Account type",
    path: "account_type",
    copyToClipBoardCallBack: (value) => replaceCommaAndNewLineSymbols(value),
  },
  {
    by: "id_and_poa",
    title: "ID and POA",
    path: "id_and_poa",
    getCellValue: (value) => <RenderCellValue value={value as unknown as string} />,
    copyToClipBoardCallBack: (value) => replaceCommaAndNewLineSymbols(value),
  },
  {
    by: "tax_form",
    title: "Tax form",
    path: "tax_form",
    getCellValue: (value) => <RenderCellValue value={value as unknown as string} />,
    copyToClipBoardCallBack: (value) => replaceCommaAndNewLineSymbols(value),
  },
];

export const HELIOS_AND_APPIUS_LIVE_TIF_COLUMNS: Array<IColum> = [
  {
    by: "timestamp",
    title: "Submission timestamp",
    path: "timestamp",
    copyToClipBoardCallBack: (value) => replaceNewLineAndTrim(value),
  },
  {
    by: "name",
    title: "Name",
    path: "name",
    copyToClipBoardCallBack: (value) => replaceNewLineAndTrim(value),
    getCellValue: (value) => <CopyToClipboard value={value as unknown as string} />,
  },
  {
    by: "email",
    title: "Email",
    path: "email",
    copyToClipBoardCallBack: (value) => replaceNewLineAndTrim(value),
    getCellValue: (value) => <CopyToClipboard value={value as unknown as string} />,
  },
  {
    by: "status",
    title: "Status",
    path: "status",
    copyToClipBoardCallBack: (value) => replaceNewLineAndTrim(value),
  },
  {
    by: "dob",
    title: "DOB",
    path: "dob",
    copyToClipBoardCallBack: (value) => replaceNewLineAndTrim(value),
  },
  {
    by: "phone",
    title: "Phone",
    path: "phone",
    copyToClipBoardCallBack: (value: string): string => {
      if (value) {
        return value.replaceAll("+", "").replaceAll("\n", " ");
      }
      return "";
    },
  },
  {
    by: "address",
    title: "Address",
    path: "address",
    copyToClipBoardCallBack: (value) => replaceCommaAndNewLineSymbols(value),
  },
  {
    by: "account_type",
    title: "Account type",
    path: "account_type",
    copyToClipBoardCallBack: (value) => replaceCommaAndNewLineSymbols(value),
  },
  {
    by: "id_and_poa",
    title: "ID and POA",
    path: "id_and_poa",
    getCellValue: (value) => <RenderCellValue value={value as unknown as string} />,
    copyToClipBoardCallBack: (value) => replaceCommaAndNewLineSymbols(value),
  },
  {
    by: "tax_form",
    title: "Tax form",
    path: "tax_form",
    getCellValue: (value) => <RenderCellValue value={value as unknown as string} />,
    copyToClipBoardCallBack: (value) => replaceCommaAndNewLineSymbols(value),
  },
];
