import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";

import SearchByListValues from "../SearchByListValues";
import useStyles from "../../useStyles";
import usePagination from "../../../../hooks/usePagination";
import { EMAILS_LIST_VALIDATION_SCHEMA } from "../../../../utils/validation";
import { getQueryStringByStringValues } from "../../utils";
import { ComplianceAPI } from "../../../../api/compliance";
import { ComplianceSearchActions } from "../../../../reducers/ComplianceSearchReducer/actions";
import { COLUMNS, TRADED_ACCOUNTS_COLUMNS } from "./constants";
import Table from "../Table";
import { useComplianceSearchContext } from "../../../../context";

type ISubTableData = {
  id: string;
  account: string;
  start: string;
  canceled: string;
  passed: string;
  trailing_history_start: string;
  trailing_history_end: string;
};
interface IDataToRender {
  email: string;
  id: number;
  name: string;
  sub_table_data: ISubTableData[];
}
const EvaluationsRithmicAccounts: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const pagination = usePagination();

  const { tabsTablesSearchState, setTabsTablesSearchState } = useComplianceSearchContext();
  const { emails } = tabsTablesSearchState.allAccountSearchTab.evaluationsRithmicAccounts.search;

  const [dataToRender, setDataToRender] = useState<IDataToRender[]>([]);
  const { loading, data } = useSelector((state) => state.complianceSearch.rithmicAccounts);

  useQuery({
    retry: false,
    enabled: !!emails,
    queryKey: [emails, pagination.page, pagination.limit],
    queryFn: async ({ signal }) => {
      dispatch(ComplianceSearchActions.fetchComplianceSearchRithmicAccountsRequest());
      const response = await ComplianceAPI.getEvaluationsRithmicAccountsRequest(
        getQueryStringByStringValues("emails", emails),
        signal
      );
      dispatch(ComplianceSearchActions.fetchComplianceSearchRithmicAccountsSuccess(response.data));
      return response;
    },
    onError: (error) => {
      console.log("error EvaluationsRithmicAccounts fetching data", error);
      dispatch(ComplianceSearchActions.fetchComplianceSearchRithmicAccountsFailed());
    },
  });

  useEffect(() => {
    pagination.setPage(0);
  }, [emails]);

  const handleChangeSearchValue = (values: { emails: string }) => {
    setTabsTablesSearchState({
      ...tabsTablesSearchState,
      allAccountSearchTab: {
        ...tabsTablesSearchState.allAccountSearchTab,
        evaluationsRithmicAccounts: {
          search: { emails: values.emails },
        },
      },
    });
  };

  useEffect(() => {
    const dataToRender: IDataToRender[] = [];
    data.accounts.forEach((account) => {
      const d: IDataToRender = { ...account, sub_table_data: [] };

      const tradedAccounts = data.tradedAccounts.filter(({ email }) => email === account.email);
      const sub_table_data: ISubTableData[] = [];
      tradedAccounts.forEach((traded_account) => {
        const trailing_history = data.trailing_history.filter(
          ({ account }) => account === traded_account.account
        );
        const evaluation_completed_history = data.evaluation_completed_history.find(
          ({ e2t_account_id }) => e2t_account_id === traded_account.account
        );

        const traded_account_data = {
          id: traded_account.account,
          account: traded_account.account,
          start: evaluation_completed_history?.account_plan.start || "",
          canceled: evaluation_completed_history?.account_plan.canceled || "",
          passed: evaluation_completed_history?.timestamp || "",
          trailing_history_start: trailing_history.at(-1)?.timestamp || "",
          trailing_history_end: trailing_history.at(0)?.timestamp || "",
        };

        sub_table_data.push(traded_account_data);
      });
      d.sub_table_data.push(...sub_table_data);
      dataToRender.push(d);
    });
    setDataToRender(dataToRender);
  }, [data.accounts]);

  return (
    <div>
      <b className={classes.title}>Search rithmic accounts by email</b>

      <SearchByListValues
        fetchData={handleChangeSearchValue}
        isLoading={false}
        label="Emails"
        name="emails"
        placeholder="john@gmail.com, doe@gmail.com e.t.c ..."
        initialValues={{ emails }}
        validationSchema={EMAILS_LIST_VALIDATION_SCHEMA}
      />

      <Table
        columns={COLUMNS}
        subColumns={TRADED_ACCOUNTS_COLUMNS}
        extractKey={(r) => r.id}
        loading={loading}
        data={dataToRender}
        colSpan={6}
        csv_table_name="E2T All accounts search - Search rithmic accounts by email"
      />
    </div>
  );
};

export default EvaluationsRithmicAccounts;
