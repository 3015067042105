import * as T from "../../api/compliance/index.types";
import { ComplianceSearchAccountsAction, ComplianceSearchAccountsActionType } from "./index.types";
import { ILiveSimTakers } from "../../api/compliance/index.types";

export const ComplianceSearchActions = {
  //SearchAccounts
  fetchComplianceSearchRequest: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchComplianceSearchAccountsRequest,
  }),
  fetchComplianceSearchSuccess: (
    payload: T.IGetAllEntriesResponse["data"]["accounts_table"]
  ): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchComplianceSearchAccountsSuccess,
    payload,
  }),
  fetchComplianceSearchFailed: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchComplianceSearchAccountsFailed,
  }),
  //Search logins
  fetchComplianceSearchLoginsRequest: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchComplianceSearchLoginsRequest,
  }),
  fetchComplianceSearchLoginsSuccess: (
    payload: T.IGetCustomersLoginsResponse["data"]["logins"]
  ): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchComplianceSearchLoginsSuccess,
    payload,
  }),
  fetchComplianceSearchLoginsFailed: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchComplianceSearchLoginsFailed,
  }),
  // Search unique req ip
  fetchComplianceSearchUniqueReqIpRequest: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchComplianceSearchUniqueReqIpRequest,
  }),
  fetchComplianceSearchUniqueReqIpSuccess: (
    payload: T.IUniqueReqIpResponse["data"]["usageData"]
  ): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchComplianceSearchUniqueReqIpSuccess,
    payload,
  }),
  fetchComplianceSearchUniqueReqIpFailed: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchComplianceSearchUniqueReqIpFailed,
  }),
  // SearchPaymentCards
  fetchComplianceSearchPaymentCardsRequest: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchComplianceSearchPaymentCardsRequest,
  }),
  fetchComplianceSearchPaymentCardsSuccess: (
    payload: T.IGetPaymentCardsResponse["data"]["paymentCards"]
  ): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchComplianceSearchPaymentCardsSuccess,
    payload,
  }),
  fetchComplianceSearchPaymentCardsFailed: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchComplianceSearchPaymentCardsFailed,
  }),
  // SearchPlans
  fetchComplianceSearchPlansRequest: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchComplianceSearchPlansRequest,
  }),
  fetchComplianceSearchPlansSuccess: (
    payload: T.IGetAccountPlansDataResponse["data"]
  ): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchComplianceSearchPlansSuccess,
    payload,
  }),
  fetchComplianceSearchPlansFailed: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchComplianceSearchPlansFailed,
  }),
  // SearchPlansStatus
  fetchComplianceSearchPlansStatusRequest: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchComplianceSearchPlansStatusRequest,
  }),
  fetchComplianceSearchPlansStatusSuccess: (
    payload: T.IGetAccountPlansStatusResponse["data"]["accounts"]
  ): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchComplianceSearchPlansStatusSuccess,
    payload,
  }),
  fetchComplianceSearchPlansStatusFailed: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchComplianceSearchPlansStatusFailed,
  }),
  // SearchPurchases
  fetchComplianceSearchPurchasesRequest: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchComplianceSearchPurchasesRequest,
  }),
  fetchComplianceSearchPurchasesSuccess: (
    payload: T.TGetAccountsPurchasesResponse["data"]["purchasesHistory"]
  ): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchComplianceSearchPurchasesSuccess,
    payload,
  }),
  fetchComplianceSearchPurchasesFailed: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchComplianceSearchPurchasesFailed,
  }),
  // SearchResetsRebillsNewPurchases
  fetchComplianceSearchResetsRebillsNewPurchasesRequest: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchSearchResetsRebillsNewPurchasesRequest,
  }),
  fetchComplianceSearchResetsRebillsNewPurchasesSuccess: (
    payload: T.IGetAccountsResetsRebillsNewPurchasesResponse["data"]["accounts"]
  ): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchSearchResetsRebillsNewPurchasesSuccess,
    payload,
  }),
  fetchComplianceSearchResetsRebillsNewPurchasesFailed: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchSearchResetsRebillsNewPurchasesFailed,
  }),
  // BasicAccountDetailsAndSalesValue
  fetchComplianceSearchBasicAccountDetailsAndSalesValueRequest:
    (): ComplianceSearchAccountsAction => ({
      type: ComplianceSearchAccountsActionType.fetchBasicAccountDetailsAndSalesValueRequest,
    }),
  fetchComplianceSearchBasicAccountDetailsAndSalesValueSuccess: (
    payload: T.IBasicAccountDetailsAndSalesValueResponse["data"]["accounts"]
  ): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchBasicAccountDetailsAndSalesValueSuccess,
    payload,
  }),
  fetchComplianceSearchBasicAccountDetailsAndSalesValueFailed:
    (): ComplianceSearchAccountsAction => ({
      type: ComplianceSearchAccountsActionType.fetchBasicAccountDetailsAndSalesValueFailed,
    }),
  // AccountSearchOnNameVariations
  fetchComplianceSearchByNameRequest: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchSearchAccountsOnNameVariationsRequest,
  }),
  fetchComplianceSearchByNameSuccess: (
    payload: T.IGetAllEntriesRequestByValuesResponse["data"]
  ): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchSearchAccountsOnNameVariationsSuccess,
    payload,
  }),
  fetchComplianceSearchByNameFailed: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchSearchAccountsOnNameVariationsFailed,
  }),
  // AccountSearchByPhone
  fetchComplianceSearchByPhoneRequest: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchComplianceAccountsByPhoneRequest,
  }),
  fetchComplianceSearchByPhoneSuccess: (
    payload: T.IGetAllEntriesRequestByValuesResponse["data"]
  ): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchComplianceAccountsByPhoneSuccess,
    payload,
  }),
  fetchComplianceSearchByPhoneFailed: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchComplianceAccountsByPhoneFailed,
  }),
  // SearchRithmicAccounts
  fetchComplianceSearchRithmicAccountsRequest: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchRithmicAccountsRequest,
  }),
  fetchComplianceSearchRithmicAccountsSuccess: (
    payload: T.IGetEvaluationsRithmicAccountsResponse["data"]
  ): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchRithmicAccountsSuccess,
    payload,
  }),
  fetchComplianceSearchRithmicAccountsFailed: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchRithmicAccountsFailed,
  }),
  // WatchlistPasses
  fetchWatchlistPassesRequest: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchWatchlistSubmissionToPropRequest,
  }),
  fetchWatchlistPassesSuccess: (
    payload: T.IGetPassesResponse["data"]
  ): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchWatchlistSubmissionToPropSuccess,
    payload,
  }),
  fetchWatchlistPassesFailed: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchWatchlistSubmissionToPropFailed,
  }),
  // WatchlistWithdrawals
  fetchWatchlistWithdrawalsRequest: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchWatchlistWithdrawalsRequest,
  }),
  fetchWatchlistWithdrawalsSuccess: (
    payload: T.IWithdrawalsResponse["data"]
  ): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchWatchlistWithdrawalsSuccess,
    payload,
  }),
  fetchWatchlistWithdrawalsFailed: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchWatchlistWithdrawalsFailed,
  }),
  // WatchlistFailed
  fetchWatchlistFailedRequest: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchWatchlistFailedRequest,
  }),
  fetchWatchlistFailedSuccess: (
    payload: T.IFailedResponse["data"]
  ): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchWatchlistFailedSuccess,
    payload,
  }),
  fetchWatchlistFailedFailed: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchWatchlistFailedFailed,
  }),
  // WatchlistLiveSimTif
  fetchWatchlistLiveSimTifRequest: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchWatchlistLiveSimTifRequest,
  }),
  fetchWatchlistLiveSimTifSuccess: (
    payload: T.IGetWatchlistLiveSimTifResponse["data"]
  ): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchWatchlistLiveSimTifSuccess,
    payload,
  }),
  fetchWatchlistLiveSimTifFailed: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchWatchlistLiveSimTifFailed,
  }),
  // WatchlistLiveTif
  fetchWatchlistLiveTifRequest: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchWatchlistLiveTifRequest,
  }),
  fetchWatchlistLiveTifSuccess: (
    payload: T.IGetWatchlistLiveTifResponse["data"]
  ): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchWatchlistLiveTifSuccess,
    payload,
  }),
  fetchWatchlistLiveTifFailed: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchWatchlistLiveTifFailed,
  }),
  // WatchlistAppiusLiveTif
  fetchWatchlistAppiusLiveTifRequest: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchWatchlistAppiusLiveTifRequest,
  }),
  fetchWatchlistAppiusLiveTifSuccess: (
    payload: T.IGetWatchlistAppiusLiveTifResponse["data"]
  ): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchWatchlistAppiusLiveTifSuccess,
    payload,
  }),
  fetchWatchlistAppiusLiveTifFailed: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchWatchlistAppiusLiveTifFailed,
  }),
  // WatchlistNotes
  fetchWatchlistNotesRequest: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchWatchlistNotesRequest,
  }),
  fetchWatchlistNotesSuccess: (
    payload: T.IGetSearchNotesResponse["data"]
  ): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchWatchlistNotesSuccess,
    payload,
  }),
  fetchWatchlistNotesFailed: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchWatchlistNotesFailed,
  }),
  // HeliosAccountsInvestigation
  fetchHeliosAccountsInvestigationRequest: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchHeliosAccountsInvestigationRequest,
  }),
  fetchHeliosAccountsInvestigationSuccess: (
    payload: T.IGetHeliosRelatedSearchResponse["data"]
  ): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchHeliosAccountsInvestigationSuccess,
    payload,
  }),
  fetchHeliosAccountsInvestigationFailed: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchHeliosAccountsInvestigationFailed,
  }),
  // PnLCounter
  fetchPnLCounterRequest: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchPnLCounterRequest,
  }),
  fetchPnLCounterSuccess: (
    payload: T.IGetPnlDataResponse["data"]
  ): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchPnLCounterSuccess,
    payload,
  }),
  fetchPnLCounterFailed: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchPnLCounterFailed,
  }),
  // HeliosAndAppiusLiveTif
  fetchWatchlistHeliosAndAppiusLiveTifRequest: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchWatchlistHeliosAndAppiusLiveTifRequest,
  }),
  fetchWatchlistHeliosAndAppiusLiveTifSuccess: (
    payload: T.IGetWatchlistHeliosAppiusLiveTifResponse["data"]
  ): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchWatchlistHeliosAndAppiusLiveTifSuccess,
    payload,
  }),
  fetchWatchlistHeliosAndAppiusLiveTifFailed: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchWatchlistHeliosAndAppiusLiveTifFailed,
  }),
  // TopLiveSimTakers
  fetchTopLiveSimTakersRequest: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchLiveSimTakersRequest,
  }),
  fetchTopLiveSimTakersSuccess: (payload: {
    liveSimTakers: ILiveSimTakers[];
    updated: { timestamp: string };
  }): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchLiveSimTakersSuccess,
    payload,
  }),
  fetchTopLiveSimTakersFailed: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchLiveSimTakersFailed,
  }),
  // TopLiveLosers
  fetchTopLiveLosersRequest: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchTopLiveLosersRequest,
  }),
  fetchTopLiveLosersSuccess: (payload: {
    data: {
      liveLosers: Array<{ name: string; amount: number }>;
      updated: { timestamp: string };
    };
  }): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchTopLiveLosersSuccess,
    payload,
  }),
  fetchTopLiveLosersFailed: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchTopLiveLosersFailed,
  }),
};
