import { IComplianceSearchContextProps } from "./index";

export const tabsTablesSearchInitialState: IComplianceSearchContextProps["tabsTablesSearchState"] =
  {
    allAccountSearchTab: {
      searchAccountsByName: {
        search: {
          names: "",
        },
      },
      searchAccountsByPhone: {
        search: {
          phones: "",
        },
      },
      evaluationsRithmicAccounts: {
        search: {
          emails: "",
        },
      },
    },
    quickSearchTab: {
      subscriptionStatusTable: {
        search: {
          emails: "",
        },
      },
      allPurchasesTable: {
        search: {
          emails: "",
        },
      },
      resetsRebillsNewPurchasesTable: {
        search: {
          emails: "",
        },
      },
      searchAccountsPlansTable: {
        search: {
          emails: "",
        },
      },
      basicAccountDetailsAndSalesValue: {
        search: {
          emails: "",
        },
      },
    },
    fundedAccountQuickSearchTab: {
      heliosPassesSearchTable: {
        search: {
          names: "",
        },
      },
      heliosWithdrawalsSearchTable: {
        search: {
          names: "",
        },
      },
      heliosFailedSearchTable: {
        search: {
          names: "",
        },
      },
      heliosLiveSIMTraderInformationFormSearchTable: {
        search: {
          names: "",
          email: "",
          status: "",
        },
      },
      heliosAndAppiusLiveSearchTable: {
        search: {
          names: "",
          phone: "",
          email: "",
          address: "",
        },
      },
    },
    fundedAccountInvestigationTab: {
      fundedAccountsInvestigationSearchTable: {
        search: {
          names: "",
          email: "",
          phone: "",
          address: "",
        },
      },
    },
    watchListPnlTab: {
      pnLCalculatorTable: {
        search: {
          names: "",
          email: "",
        },
      },
      heliosNotesSearchTable: {
        search: {
          names: "",
          email: "",
        },
      },
    },
  };
