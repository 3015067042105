import React, { useState } from "react";
import cx from "classnames";
import { useParams } from "react-router-dom";
import moment from "moment-timezone";
import { Typography } from "@material-ui/core/";
import { useRouteMatch } from "react-router-dom";

import useStyles from "./useStyles";
import {
  EditableText,
  SearchAutocompleteWithActions,
  MainInfoValue,
  CopyToClipboard,
} from "../index";
import { useCustomerProvider, useIntercomContext } from "../../../context";
import { APIAccounts, APICountries, APIIntercom } from "../../../api";
import {
  validateSchemaEmail,
  validateSchemaZip,
  validateSchemaAddress2,
  validateSchemaCity,
  validateSchemaStateprov,
  validateSchemaAddress,
} from "./utils";
import { getGauntletAccountName } from "../../../pages/Customer/components/IntercomData/utils";
import { CA_AND_US_STATES_OPTIONS } from "../../../constants";

const BaseCustomerInfo: React.FC = () => {
  const classes = useStyles();
  const { id } = useParams();
  const matchFundedTraderPage = useRouteMatch("/fundedTraders/:id");

  const { customer, setCustomer, getCustomer, fina } = useCustomerProvider();
  const { getIntercomContact } = useIntercomContext();

  const [email, setEmail] = useState<string>("");
  const [zip, setZip] = useState<string>(customer.zip);
  const [address, setAddress] = useState<string>(customer.address);
  const [address2, setAddress2] = useState<string>(customer.address);
  const [city, setCity] = useState<string>(customer.city);
  const [stateprov, setStateprov] = useState<string>(customer.stateprov);
  const [phone, setPhone] = useState<string>(customer.phone);

  const saveICCustomer = async (xid: string) => {
    try {
      await APIIntercom.saveICCustomerRequest(xid, { id, email });
      setCustomer({ ...customer, email });
      // update intercom section
      getIntercomContact(fina.id);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div
      className={cx(classes.innerContainer, {
        [classes.innerContainerFundedTraderPage]: matchFundedTraderPage,
      })}
    >
      <div className={classes.mainContainer}>
        <Typography classes={{ root: classes.title }} variant="subtitle2">
          Name:
        </Typography>
        <CopyToClipboard value={customer.name} />
      </div>
      <div>
        <EditableText
          isAllowedEditing
          isWithCopyToClipboard
          data-test="edit-email"
          title="Email: "
          value={customer.email || "N/A"}
          onSave={() => saveICCustomer(customer.xid)}
          name="email"
          label="Email"
          setValue={setEmail}
          validationSchema={validateSchemaEmail}
        />
      </div>
      <div>
        <MainInfoValue title="Country">
          <SearchAutocompleteWithActions
            isShowEditIcon
            data-test="edit-country"
            ParentComponent="div"
            label="Country"
            currentValue={customer.country.name || "N/A"}
            onSearch={APICountries.getCountriesListRequest}
            containerClass={{
              [classes.countryContainer]: true,
            }}
            onUpdate={async (selectedValue) => {
              // @ts-ignore
              await APIAccounts.updateAccountDataRequest(id, { countries_id: selectedValue.id });
            }}
            onUpdated={getCustomer}
          />
        </MainInfoValue>
        <EditableText
          isAllowedEditing
          isWithCopyToClipboard
          title="City: "
          value={customer.city || "N/A"}
          name="city"
          label="City"
          setValue={setCity}
          onSave={async () => {
            await APIAccounts.updateAccountDataRequest(id, { city });
            await getCustomer();
          }}
          validationSchema={validateSchemaCity}
        />
        {customer.country.iso2 === "US" ? (
          <MainInfoValue title="Stateprov">
            <SearchAutocompleteWithActions
              isShowEditIcon
              ParentComponent="div"
              label="Stateprov"
              currentValue={customer.stateprov || "N/A"}
              onSearch={async () => {
                return await new Promise((res) => res({ data: CA_AND_US_STATES_OPTIONS }));
              }}
              currentOptions={CA_AND_US_STATES_OPTIONS}
              onUpdate={async (selectedValue) => {
                await APIAccounts.updateAccountDataRequest(id, {
                  stateprov: selectedValue.name as string,
                });
              }}
              containerClass={{
                [classes.countryContainer]: true,
              }}
              onUpdated={getCustomer}
            />
          </MainInfoValue>
        ) : (
          <EditableText
            isAllowedEditing
            isWithCopyToClipboard
            title="Stateprov: "
            value={customer.stateprov || "N/A"}
            name="stateprov"
            label="Stateprov"
            setValue={setStateprov}
            onSave={async () => {
              await APIAccounts.updateAccountDataRequest(id, { stateprov });
              await getCustomer();
            }}
            validationSchema={validateSchemaStateprov}
          />
        )}

        <EditableText
          isAllowedEditing
          isWithCopyToClipboard
          title="ZIP: "
          value={customer.zip || "N/A"}
          name="zip"
          label="ZIP"
          setValue={setZip}
          onSave={async () => {
            await APIAccounts.updateAccountDataRequest(id, { zip });
            await getCustomer();
          }}
          validationSchema={validateSchemaZip}
        />
        <EditableText
          isAllowedEditing
          isWithCopyToClipboard
          title="Address: "
          value={customer.address || "N/A"}
          name="address"
          label="Address"
          setValue={setAddress}
          onSave={async () => {
            await APIAccounts.updateAccountDataRequest(id, { address });
            await getCustomer();
          }}
          validationSchema={validateSchemaAddress}
        />
        <EditableText
          isAllowedEditing
          isWithCopyToClipboard
          title="Address2: "
          value={customer.address2}
          name="address2"
          label="Address2"
          setValue={setAddress2}
          onSave={async () => {
            await APIAccounts.updateAccountDataRequest(id, {
              address2: address2 ? address2 : null,
            });
            await getCustomer();
          }}
          validationSchema={validateSchemaAddress2}
        />
      </div>
      <EditableText
        isAllowedEditing
        isWithCopyToClipboard
        title="Phone: "
        value={customer.phone}
        name="phone"
        label="Phone"
        setValue={setPhone}
        onSave={async () => {
          await APIAccounts.updateAccountDataRequest(id, {
            phone,
          });
          await getCustomer();
        }}
        validationSchema={validateSchemaAddress2}
      />

      <EditableText
        isAllowedEditing
        isWithCopyToClipboard
        title="Address2: "
        value={customer.address2}
        name="address2"
        label="Address2"
        setValue={setAddress2}
        onSave={async () => {
          await APIAccounts.updateAccountDataRequest(id, {
            address2: address2 ? address2 : null,
          });
          await getCustomer();
        }}
        validationSchema={validateSchemaAddress2}
      />
      <MainInfoValue title="Local Time">
        {customer.country?.timezone &&
          moment().tz(customer.country.timezone.timezones[0]).format("HH:mm:ss")}
      </MainInfoValue>
      <MainInfoValue title="Joined">
        {moment(customer.joined).tz("America/Chicago").format("YYYY-MM-DD")}
      </MainInfoValue>
      <MainInfoValue title="Registration IP">
        <a
          style={{ cursor: "help" }}
          href={`https://whatismyipaddress.com/ip/${customer.reg_ip}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {customer.reg_ip}
        </a>
      </MainInfoValue>
      {matchFundedTraderPage && (
        <MainInfoValue title="E2T Account ID">{getGauntletAccountName(customer)}</MainInfoValue>
      )}
    </div>
  );
};

export default BaseCustomerInfo;
