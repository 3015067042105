import React from "react";

import SubscriptionStatusTable from "./SubscriptionStatusTable";
import AllPurchases from "./AllPurchases";
import ResetsRebillsNewPurchases from "./ResetsRebillsNewPurchases";
import BasicAccountDetailsAndSalesValue from "./BasicAccountDetailsAndSalesValue";
import SearchAccountsPlans from "../SearchAccountsPlans";

const QuickSearch = (): React.ReactElement => {
  return (
    <div>
      <SubscriptionStatusTable />
      <AllPurchases />
      <ResetsRebillsNewPurchases />
      <SearchAccountsPlans />
      <BasicAccountDetailsAndSalesValue />
    </div>
  );
};

export default QuickSearch;
