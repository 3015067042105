import React, { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { uniqueId } from "lodash";

import useStyles from "../../useStyles";
import { ComplianceSearchActions } from "../../../../reducers/ComplianceSearchReducer/actions";
import { COMPLIANCE_API_URLS, ComplianceAPI } from "../../../../api/compliance";
import { appendSearchParamsToQuery, TitleUpdated } from "../../utils";

import Table from "../Table";
import { COLUMNS } from "./constants";
import { FilterAutocomplete } from "../../../../components/ui";
import { useComplianceSearchContext } from "../../../../context";

interface IRowData {
  name: string;
  w20: number | undefined;
  w80: number | undefined;
  liveLosses: number | undefined;
}
const PnLCalculator: React.FC = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { tabsTablesSearchState, setTabsTablesSearchState } = useComplianceSearchContext();
  const { search } = tabsTablesSearchState.watchListPnlTab.pnLCalculatorTable;

  const [tableData, setTableData] = useState<IRowData[]>([]);
  const { data, loading } = useSelector((state) => state.complianceSearch.pnlCounter);

  useQuery({
    retry: false,
    enabled: !!search,
    queryKey: [COMPLIANCE_API_URLS.getPnlData, search],
    async queryFn({ signal }) {
      dispatch(ComplianceSearchActions.fetchPnLCounterRequest());
      const query = new URLSearchParams();
      appendSearchParamsToQuery(search, query);
      const response = await ComplianceAPI.getPnlDataRequest(query.toString(), signal);
      dispatch(ComplianceSearchActions.fetchPnLCounterSuccess(response.data));
      return response;
    },
    onError(error) {
      console.error("Error fetching pnl data", error);
      dispatch(ComplianceSearchActions.fetchPnLCounterFailed());
    },
  });

  useEffect(() => {
    const tableData: IRowData[] = data.pnlData.map((row) => {
      const [foundNames, liveLosses, liveGain, liveSimTakeout, billingHistory] = row;

      const w20 = liveGain?.w20_sum || 0;
      const w80 = liveSimTakeout?.w80_sum || 0;
      const liveLosses_sum = liveLosses?.liveLosses_sum || 0;
      const billingHistorySum = billingHistory?.sum || 0;
      const total_pnl = billingHistorySum / 100 + w20 - w80 - liveLosses_sum;

      return {
        name: foundNames.names.join(", "),
        w20,
        w80,
        liveLosses: liveLosses_sum,
        billingHistory: billingHistorySum,
        total_pnl,
      };
    });
    setTableData(tableData);
  }, [data.pnlData]);

  return (
    <div>
      <b className={classes.title}>PNL counter</b>
      <div>
        <FilterAutocomplete
          search={search}
          setSearch={(values) => {
            setTabsTablesSearchState({
              ...tabsTablesSearchState,
              watchListPnlTab: {
                ...tabsTablesSearchState.watchListPnlTab,
                pnLCalculatorTable: {
                  search: values,
                },
              },
            });
          }}
          filters={{
            Name: "names",
            Email: "email",
          }}
        />
      </div>

      <Table
        title={<TitleUpdated value={data?.updated} />}
        columns={COLUMNS}
        loading={loading}
        extractKey={(row) => `${row.name}_${uniqueId()}`}
        data={tableData}
        csv_table_name="Wathclist, Pnl - PNL counter"
      />
    </div>
  );
};

export default PnLCalculator;
