import {
  IGetAccountPlansDataResponse,
  IGetAccountPlansStatusResponse,
  IGetAccountsResetsRebillsNewPurchasesResponse,
  IGetAllEntriesResponse,
  IGetCustomersLoginsResponse,
  IGetPaymentCardsResponse,
  IUniqueReqIpResponse,
  TGetAccountsPurchasesResponse,
  IBasicAccountDetailsAndSalesValueResponse,
  IGetAllEntriesRequestByValuesResponse,
  IGetEvaluationsRithmicAccountsResponse,
  IGetPassesResponse,
  IWithdrawalsResponse,
  IFailedResponse,
  IGetWatchlistLiveSimTifResponse,
  IGetWatchlistLiveTifResponse,
  IGetWatchlistAppiusLiveTifResponse,
  IGetSearchNotesResponse,
  IGetHeliosRelatedSearchResponse,
  IGetPnlDataResponse,
  IGetWatchlistHeliosAppiusLiveTifResponse,
  ILiveSimTakers,
} from "../../api/compliance/index.types";

export type ComplianceSearchAccountsAction =
  //SearchAccounts
  | ComplianceSearchAccountsActionsFetchRequest
  | ComplianceSearchAccountsActionsFetchSuccess
  | ComplianceSearchAccountsActionsFetchFailed
  //Search logins
  | ComplianceSearchLoginsActionsFetchRequest
  | ComplianceSearchLoginsActionsFetchSuccess
  | ComplianceSearchLoginsActionsFetchFailed
  // Search unique req ip
  | ComplianceSearchUniqueReqIpActionsFetchRequest
  | ComplianceSearchUniqueReqIpActionsFetchSuccess
  | ComplianceSearchUniqueReqIpActionsFetchFailed
  // SearchPaymentCards
  | ComplianceSearchPaymentCardsActionsFetchRequest
  | ComplianceSearchPaymentCardsActionsFetchSuccess
  | ComplianceSearchPaymentCardsActionsFetchFailed
  // SearchPlans
  | ComplianceSearchPlansActionsFetchRequest
  | ComplianceSearchPlansActionsFetchSuccess
  | ComplianceSearchPlansActionsFetchFailed
  // SearchPlansStatus
  | ComplianceSearchPlansStatusActionsFetchRequest
  | ComplianceSearchPlansStatusActionsFetchSuccess
  | ComplianceSearchPlansStatusActionsFetchFailed
  // SearchPurchases
  | ComplianceSearchPurchasesActionsFetchRequest
  | ComplianceSearchPurchasesActionsFetchSuccess
  | ComplianceSearchPurchasesActionsFetchFailed
  // SearchResetsRebillsNewPurchases
  | ComplianceSearchResetsRebillsNewPurchasesActionsFetchRequest
  | ComplianceSearchResetsRebillsNewPurchasesActionsFetchSuccess
  | ComplianceSearchResetsRebillsNewPurchasesActionsFetchFailed
  // BasicAccountDetailsAndSalesValue
  | ComplianceSearchBasicAccountDetailsAndSalesValueActionsFetchRequest
  | ComplianceSearchBasicAccountDetailsAndSalesValueActionsFetchSuccess
  | ComplianceSearchBasicAccountDetailsAndSalesValueActionsFetchFailed
  // AccountsOnNameVariations
  | ComplianceSearchAccountsOnNameVariationsActionsFetchRequest
  | ComplianceSearchAccountsOnNameVariationsActionsFetchSuccess
  | ComplianceSearchAAccountsOnNameVariationsActionsFetchFailed
  // AllAccountSearchByPhone
  | ComplianceSearchAccountsByPhoneActionsFetchRequest
  | ComplianceSearchAccountsByPhoneActionsFetchSuccess
  | ComplianceSearchAccountsByPhoneActionsFetchFailed
  // SearchRithmicAccounts
  | ComplianceSearchRithmicAccountsActionsFetchRequest
  | ComplianceSearchRithmicAccountsActionsFetchSuccess
  | ComplianceSearchRithmicAccountsActionsFetchFailed
  // WatchlistSubmissionToProp
  | ComplianceSearchWatchlistSubmissionToPropActionsFetchRequest
  | ComplianceSearchWatchlistSubmissionToPropActionsFetchSuccess
  | ComplianceSearchWatchlistSubmissionToPropActionsFetchFailed
  // WatchlistWithdrawals
  | ComplianceSearchWatchlistWithdrawalsActionsFetchRequest
  | ComplianceSearchWatchlistWithdrawalsActionsFetchSuccess
  | ComplianceSearchWatchlistWithdrawalsActionsFetchFailed
  // WatchlistFailed
  | ComplianceSearchWatchlistFailedActionsFetchRequest
  | ComplianceSearchWatchlistFailedActionsFetchSuccess
  | ComplianceSearchWatchlistFailedActionsFetchFailed
  // WatchlistLiveSimTif
  | ComplianceSearchWatchlistLiveSimTifActionsFetchRequest
  | ComplianceSearchWatchlistLiveSimTifActionsFetchSuccess
  | ComplianceSearchWatchlistLiveSimTifActionsFetchFailed
  // WatchlistLiveTif
  | ComplianceSearchWatchlistLiveTifActionsFetchRequest
  | ComplianceSearchWatchlistLiveTifActionsFetchSuccess
  | ComplianceSearchWatchlistLiveTifActionsFetchFailed
  // WatchlistAppiusLiveTif
  | ComplianceSearchWatchlistAppiusLiveTifActionsFetchRequest
  | ComplianceSearchWatchlistAppiusLiveTifActionsFetchSuccess
  | ComplianceSearchWatchlistAppiusLiveTifActionsFetchFailed
  // WatchlistNotes
  | ComplianceSearchWatchlistNotesActionsFetchRequest
  | ComplianceSearchWatchlistNotesActionsFetchSuccess
  | ComplianceSearchWatchlistNotesActionsFetchFailed
  // HeliosAccountsInvestigation
  | ComplianceSearchHeliosAccountsInvestigationFetchRequest
  | ComplianceSearchHeliosAccountsInvestigationFetchSuccess
  | ComplianceSearchHeliosAccountsInvestigationFetchFailed
  // PnLCounter
  | ComplianceSearchPnLCounterActionsFetchRequest
  | ComplianceSearchPnLCounterActionsFetchSuccess
  | ComplianceSearchPnLCounterActionsFetchFailed
  // HeliosAndAppiusLiveTif
  | ComplianceSearchWatchlistHeliosAndAppiusLiveTifActionsFetchRequest
  | ComplianceSearchWatchlistHeliosAndAppiusLiveTifActionsFetchSuccess
  | ComplianceSearchWatchlistHeliosAndAppiusLiveTifActionsFetchFailed
  // TopLiveSimTakers
  | ComplianceSearchLiveSimTakersActionsFetchRequest
  | ComplianceSearchLiveSimTakersActionsFetchSuccess
  | ComplianceSearchLiveSimTakersActionsFetchFailed
  // TopLiveLosers
  | ComplianceSearchTopLiveLosersActionsFetchRequest
  | ComplianceSearchTopLiveLosersActionsFetchSuccess
  | ComplianceSearchTopLiveLosersActionsFetchFailed;

//SearchAccounts
export interface ComplianceSearchAccountsActionsFetchRequest {
  type: ComplianceSearchAccountsActionType.fetchComplianceSearchAccountsRequest;
}
export interface ComplianceSearchAccountsActionsFetchSuccess {
  type: ComplianceSearchAccountsActionType.fetchComplianceSearchAccountsSuccess;
  payload: IGetAllEntriesResponse["data"]["accounts_table"];
}
export interface ComplianceSearchAccountsActionsFetchFailed {
  type: ComplianceSearchAccountsActionType.fetchComplianceSearchAccountsFailed;
}
//Search logins
export interface ComplianceSearchLoginsActionsFetchRequest {
  type: ComplianceSearchAccountsActionType.fetchComplianceSearchLoginsRequest;
}
export interface ComplianceSearchLoginsActionsFetchSuccess {
  type: ComplianceSearchAccountsActionType.fetchComplianceSearchLoginsSuccess;
  payload: IGetCustomersLoginsResponse["data"]["logins"];
}
export interface ComplianceSearchLoginsActionsFetchFailed {
  type: ComplianceSearchAccountsActionType.fetchComplianceSearchLoginsFailed;
}
// Search unique req ip
export interface ComplianceSearchUniqueReqIpActionsFetchRequest {
  type: ComplianceSearchAccountsActionType.fetchComplianceSearchUniqueReqIpRequest;
}
export interface ComplianceSearchUniqueReqIpActionsFetchSuccess {
  type: ComplianceSearchAccountsActionType.fetchComplianceSearchUniqueReqIpSuccess;
  payload: IUniqueReqIpResponse["data"]["usageData"];
}
export interface ComplianceSearchUniqueReqIpActionsFetchFailed {
  type: ComplianceSearchAccountsActionType.fetchComplianceSearchUniqueReqIpFailed;
}
// SearchPaymentCards
export interface ComplianceSearchPaymentCardsActionsFetchRequest {
  type: ComplianceSearchAccountsActionType.fetchComplianceSearchPaymentCardsRequest;
}
export interface ComplianceSearchPaymentCardsActionsFetchSuccess {
  type: ComplianceSearchAccountsActionType.fetchComplianceSearchPaymentCardsSuccess;
  payload: IGetPaymentCardsResponse["data"]["paymentCards"];
}
export interface ComplianceSearchPaymentCardsActionsFetchFailed {
  type: ComplianceSearchAccountsActionType.fetchComplianceSearchPaymentCardsFailed;
}
// SearchPlans
export interface ComplianceSearchPlansActionsFetchRequest {
  type: ComplianceSearchAccountsActionType.fetchComplianceSearchPlansRequest;
}
export interface ComplianceSearchPlansActionsFetchSuccess {
  type: ComplianceSearchAccountsActionType.fetchComplianceSearchPlansSuccess;
  payload: IGetAccountPlansDataResponse["data"];
}
export interface ComplianceSearchPlansActionsFetchFailed {
  type: ComplianceSearchAccountsActionType.fetchComplianceSearchPlansFailed;
}
// SearchPlansStatus
export interface ComplianceSearchPlansStatusActionsFetchRequest {
  type: ComplianceSearchAccountsActionType.fetchComplianceSearchPlansStatusRequest;
}
export interface ComplianceSearchPlansStatusActionsFetchSuccess {
  type: ComplianceSearchAccountsActionType.fetchComplianceSearchPlansStatusSuccess;
  payload: IGetAccountPlansStatusResponse["data"]["accounts"];
}
export interface ComplianceSearchPlansStatusActionsFetchFailed {
  type: ComplianceSearchAccountsActionType.fetchComplianceSearchPlansStatusFailed;
}
// SearchPurchases
export interface ComplianceSearchPurchasesActionsFetchRequest {
  type: ComplianceSearchAccountsActionType.fetchComplianceSearchPurchasesRequest;
}
export interface ComplianceSearchPurchasesActionsFetchSuccess {
  type: ComplianceSearchAccountsActionType.fetchComplianceSearchPurchasesSuccess;
  payload: TGetAccountsPurchasesResponse["data"]["purchasesHistory"];
}
export interface ComplianceSearchPurchasesActionsFetchFailed {
  type: ComplianceSearchAccountsActionType.fetchComplianceSearchPurchasesFailed;
}
//SearchResetsRebillsNewPurchases
export interface ComplianceSearchResetsRebillsNewPurchasesActionsFetchRequest {
  type: ComplianceSearchAccountsActionType.fetchSearchResetsRebillsNewPurchasesRequest;
}
export interface ComplianceSearchResetsRebillsNewPurchasesActionsFetchSuccess {
  type: ComplianceSearchAccountsActionType.fetchSearchResetsRebillsNewPurchasesSuccess;
  payload: IGetAccountsResetsRebillsNewPurchasesResponse["data"]["accounts"];
}
export interface ComplianceSearchResetsRebillsNewPurchasesActionsFetchFailed {
  type: ComplianceSearchAccountsActionType.fetchSearchResetsRebillsNewPurchasesFailed;
}
// BasicAccountDetailsAndSalesValue
export interface ComplianceSearchBasicAccountDetailsAndSalesValueActionsFetchRequest {
  type: ComplianceSearchAccountsActionType.fetchBasicAccountDetailsAndSalesValueRequest;
}
export interface ComplianceSearchBasicAccountDetailsAndSalesValueActionsFetchSuccess {
  type: ComplianceSearchAccountsActionType.fetchBasicAccountDetailsAndSalesValueSuccess;
  payload: IBasicAccountDetailsAndSalesValueResponse["data"]["accounts"];
}
export interface ComplianceSearchBasicAccountDetailsAndSalesValueActionsFetchFailed {
  type: ComplianceSearchAccountsActionType.fetchBasicAccountDetailsAndSalesValueFailed;
}
// AccountsOnNameVariations
export interface ComplianceSearchAccountsOnNameVariationsActionsFetchRequest {
  type: ComplianceSearchAccountsActionType.fetchSearchAccountsOnNameVariationsRequest;
}
export interface ComplianceSearchAccountsOnNameVariationsActionsFetchSuccess {
  type: ComplianceSearchAccountsActionType.fetchSearchAccountsOnNameVariationsSuccess;
  payload: IGetAllEntriesRequestByValuesResponse["data"];
}
export interface ComplianceSearchAAccountsOnNameVariationsActionsFetchFailed {
  type: ComplianceSearchAccountsActionType.fetchSearchAccountsOnNameVariationsFailed;
}
// AllAccountSearchByPhone
export interface ComplianceSearchAccountsByPhoneActionsFetchRequest {
  type: ComplianceSearchAccountsActionType.fetchComplianceAccountsByPhoneRequest;
}
export interface ComplianceSearchAccountsByPhoneActionsFetchSuccess {
  type: ComplianceSearchAccountsActionType.fetchComplianceAccountsByPhoneSuccess;
  payload: IGetAllEntriesRequestByValuesResponse["data"];
}
export interface ComplianceSearchAccountsByPhoneActionsFetchFailed {
  type: ComplianceSearchAccountsActionType.fetchComplianceAccountsByPhoneFailed;
}
// SearchRithmicAccounts
export interface ComplianceSearchRithmicAccountsActionsFetchRequest {
  type: ComplianceSearchAccountsActionType.fetchRithmicAccountsRequest;
}
export interface ComplianceSearchRithmicAccountsActionsFetchSuccess {
  type: ComplianceSearchAccountsActionType.fetchRithmicAccountsSuccess;
  payload: IGetEvaluationsRithmicAccountsResponse["data"];
}
export interface ComplianceSearchRithmicAccountsActionsFetchFailed {
  type: ComplianceSearchAccountsActionType.fetchRithmicAccountsFailed;
}
// WatchlistSubmissionToProp
export interface ComplianceSearchWatchlistSubmissionToPropActionsFetchRequest {
  type: ComplianceSearchAccountsActionType.fetchWatchlistSubmissionToPropRequest;
}
export interface ComplianceSearchWatchlistSubmissionToPropActionsFetchSuccess {
  type: ComplianceSearchAccountsActionType.fetchWatchlistSubmissionToPropSuccess;
  payload: IGetPassesResponse["data"];
}
export interface ComplianceSearchWatchlistSubmissionToPropActionsFetchFailed {
  type: ComplianceSearchAccountsActionType.fetchWatchlistSubmissionToPropFailed;
}
// WatchlistWithdrawals
export interface ComplianceSearchWatchlistWithdrawalsActionsFetchRequest {
  type: ComplianceSearchAccountsActionType.fetchWatchlistWithdrawalsRequest;
}
export interface ComplianceSearchWatchlistWithdrawalsActionsFetchSuccess {
  type: ComplianceSearchAccountsActionType.fetchWatchlistWithdrawalsSuccess;
  payload: IWithdrawalsResponse["data"];
}
export interface ComplianceSearchWatchlistWithdrawalsActionsFetchFailed {
  type: ComplianceSearchAccountsActionType.fetchWatchlistWithdrawalsFailed;
}
// WatchlistFailed
export interface ComplianceSearchWatchlistFailedActionsFetchRequest {
  type: ComplianceSearchAccountsActionType.fetchWatchlistFailedRequest;
}
export interface ComplianceSearchWatchlistFailedActionsFetchSuccess {
  type: ComplianceSearchAccountsActionType.fetchWatchlistFailedSuccess;
  payload: IFailedResponse["data"];
}
export interface ComplianceSearchWatchlistFailedActionsFetchFailed {
  type: ComplianceSearchAccountsActionType.fetchWatchlistFailedFailed;
}
// WatchlistLiveSimTif
export interface ComplianceSearchWatchlistLiveSimTifActionsFetchRequest {
  type: ComplianceSearchAccountsActionType.fetchWatchlistLiveSimTifRequest;
}
export interface ComplianceSearchWatchlistLiveSimTifActionsFetchSuccess {
  type: ComplianceSearchAccountsActionType.fetchWatchlistLiveSimTifSuccess;
  payload: IGetWatchlistLiveSimTifResponse["data"];
}
export interface ComplianceSearchWatchlistLiveSimTifActionsFetchFailed {
  type: ComplianceSearchAccountsActionType.fetchWatchlistLiveSimTifFailed;
}
// WatchlistLiveTif
export interface ComplianceSearchWatchlistLiveTifActionsFetchRequest {
  type: ComplianceSearchAccountsActionType.fetchWatchlistLiveTifRequest;
}
export interface ComplianceSearchWatchlistLiveTifActionsFetchSuccess {
  type: ComplianceSearchAccountsActionType.fetchWatchlistLiveTifSuccess;
  payload: IGetWatchlistLiveTifResponse["data"];
}
export interface ComplianceSearchWatchlistLiveTifActionsFetchFailed {
  type: ComplianceSearchAccountsActionType.fetchWatchlistLiveTifFailed;
}
// WatchlistAppiusLiveTif
export interface ComplianceSearchWatchlistAppiusLiveTifActionsFetchRequest {
  type: ComplianceSearchAccountsActionType.fetchWatchlistAppiusLiveTifRequest;
}
export interface ComplianceSearchWatchlistAppiusLiveTifActionsFetchSuccess {
  type: ComplianceSearchAccountsActionType.fetchWatchlistAppiusLiveTifSuccess;
  payload: IGetWatchlistAppiusLiveTifResponse["data"];
}
export interface ComplianceSearchWatchlistAppiusLiveTifActionsFetchFailed {
  type: ComplianceSearchAccountsActionType.fetchWatchlistAppiusLiveTifFailed;
}
// WatchlistNotes
export interface ComplianceSearchWatchlistNotesActionsFetchRequest {
  type: ComplianceSearchAccountsActionType.fetchWatchlistNotesRequest;
}
export interface ComplianceSearchWatchlistNotesActionsFetchSuccess {
  type: ComplianceSearchAccountsActionType.fetchWatchlistNotesSuccess;
  payload: IGetSearchNotesResponse["data"];
}
export interface ComplianceSearchWatchlistNotesActionsFetchFailed {
  type: ComplianceSearchAccountsActionType.fetchWatchlistNotesFailed;
}
// HeliosAccountsInvestigation
export interface ComplianceSearchHeliosAccountsInvestigationFetchRequest {
  type: ComplianceSearchAccountsActionType.fetchHeliosAccountsInvestigationRequest;
}
export interface ComplianceSearchHeliosAccountsInvestigationFetchSuccess {
  type: ComplianceSearchAccountsActionType.fetchHeliosAccountsInvestigationSuccess;
  payload: IGetHeliosRelatedSearchResponse["data"];
}
export interface ComplianceSearchHeliosAccountsInvestigationFetchFailed {
  type: ComplianceSearchAccountsActionType.fetchHeliosAccountsInvestigationFailed;
}
// PnLCounter
export interface ComplianceSearchPnLCounterActionsFetchRequest {
  type: ComplianceSearchAccountsActionType.fetchPnLCounterRequest;
}
export interface ComplianceSearchPnLCounterActionsFetchSuccess {
  type: ComplianceSearchAccountsActionType.fetchPnLCounterSuccess;
  payload: IGetPnlDataResponse["data"];
}
export interface ComplianceSearchPnLCounterActionsFetchFailed {
  type: ComplianceSearchAccountsActionType.fetchPnLCounterFailed;
}
// HeliosAndAppiusLiveTif
export interface ComplianceSearchWatchlistHeliosAndAppiusLiveTifActionsFetchRequest {
  type: ComplianceSearchAccountsActionType.fetchWatchlistHeliosAndAppiusLiveTifRequest;
}
export interface ComplianceSearchWatchlistHeliosAndAppiusLiveTifActionsFetchSuccess {
  type: ComplianceSearchAccountsActionType.fetchWatchlistHeliosAndAppiusLiveTifSuccess;
  payload: IGetWatchlistHeliosAppiusLiveTifResponse["data"];
}
export interface ComplianceSearchWatchlistHeliosAndAppiusLiveTifActionsFetchFailed {
  type: ComplianceSearchAccountsActionType.fetchWatchlistHeliosAndAppiusLiveTifFailed;
}
// TopLiveSimTakers
export interface ComplianceSearchLiveSimTakersActionsFetchRequest {
  type: ComplianceSearchAccountsActionType.fetchLiveSimTakersRequest;
}
export interface ComplianceSearchLiveSimTakersActionsFetchSuccess {
  type: ComplianceSearchAccountsActionType.fetchLiveSimTakersSuccess;
  payload: {
    liveSimTakers: ILiveSimTakers[];
    updated: { timestamp: string };
  };
}
export interface ComplianceSearchLiveSimTakersActionsFetchFailed {
  type: ComplianceSearchAccountsActionType.fetchLiveSimTakersFailed;
}
// TopLiveLosers
export interface ComplianceSearchTopLiveLosersActionsFetchRequest {
  type: ComplianceSearchAccountsActionType.fetchTopLiveLosersRequest;
}
export interface ComplianceSearchTopLiveLosersActionsFetchSuccess {
  type: ComplianceSearchAccountsActionType.fetchTopLiveLosersSuccess;
  payload: {
    data: {
      liveLosers: Array<{ name: string; amount: number }>;
      updated: { timestamp: string };
    };
  };
}
export interface ComplianceSearchTopLiveLosersActionsFetchFailed {
  type: ComplianceSearchAccountsActionType.fetchTopLiveLosersFailed;
}

export enum ComplianceSearchAccountsActionType {
  //SearchAccounts
  fetchComplianceSearchAccountsRequest = "compliance/fetchComplianceSearchAccountsRequest",
  fetchComplianceSearchAccountsSuccess = "compliance/fetchComplianceSearchAccountsSuccess",
  fetchComplianceSearchAccountsFailed = "compliance/fetchComplianceSearchAccountsFailed",
  //Search logins
  fetchComplianceSearchLoginsRequest = "compliance/fetchComplianceSearchLoginsRequest",
  fetchComplianceSearchLoginsSuccess = "compliance/fetchComplianceSearchLoginsSuccess",
  fetchComplianceSearchLoginsFailed = "compliance/fetchComplianceSearchLoginsFailed",
  // Search unique req ip
  fetchComplianceSearchUniqueReqIpRequest = "compliance/fetchComplianceSearchUniqueReqIpRequest",
  fetchComplianceSearchUniqueReqIpSuccess = "compliance/fetchComplianceSearchUniqueReqIpSuccess",
  fetchComplianceSearchUniqueReqIpFailed = "compliance/fetchComplianceSearchUniqueReqIpFailed",
  // SearchPaymentCards
  fetchComplianceSearchPaymentCardsRequest = "compliance/fetchComplianceSearchPaymentCardsRequest",
  fetchComplianceSearchPaymentCardsSuccess = "compliance/fetchComplianceSearchPaymentCardsSuccess",
  fetchComplianceSearchPaymentCardsFailed = "compliance/fetchComplianceSearchPaymentCardsFailed",
  // SearchPlans
  fetchComplianceSearchPlansRequest = "compliance/fetchComplianceSearchPlansRequest",
  fetchComplianceSearchPlansSuccess = "compliance/fetchComplianceSearchPlansSuccess",
  fetchComplianceSearchPlansFailed = "compliance/fetchComplianceSearchPlansFailed",
  // SearchPlansStatus
  fetchComplianceSearchPlansStatusRequest = "compliance/fetchComplianceSearchPlansStatusRequest",
  fetchComplianceSearchPlansStatusSuccess = "compliance/fetchComplianceSearchPlansStatusSuccess",
  fetchComplianceSearchPlansStatusFailed = "compliance/fetchComplianceSearchPlansStatusFailed",
  // SearchPurchases
  fetchComplianceSearchPurchasesRequest = "compliance/fetchComplianceSearchPurchasesRequest",
  fetchComplianceSearchPurchasesSuccess = "compliance/fetchComplianceSearchPurchasesSuccess",
  fetchComplianceSearchPurchasesFailed = "compliance/fetchComplianceSearchPurchasesFailed",
  // SearchResetsRebillsNewPurchases
  fetchSearchResetsRebillsNewPurchasesRequest = "compliance/fetchSearchResetsRebillsNewPurchasesRequest",
  fetchSearchResetsRebillsNewPurchasesSuccess = "compliance/fetchSearchResetsRebillsNewPurchasesSuccess",
  fetchSearchResetsRebillsNewPurchasesFailed = "compliance/fetchSearchResetsRebillsNewPurchasesFailed",
  // BasicAccountDetailsAndSalesValue
  fetchBasicAccountDetailsAndSalesValueRequest = "compliance/fetchBasicAccountDetailsAndSalesValueRequest",
  fetchBasicAccountDetailsAndSalesValueSuccess = "compliance/fetchBasicAccountDetailsAndSalesValueSuccess",
  fetchBasicAccountDetailsAndSalesValueFailed = "compliance/fetchBasicAccountDetailsAndSalesValueFailed",
  // AccountsOnNameVariations
  fetchSearchAccountsOnNameVariationsRequest = "compliance/fetchSearchAccountsOnNameVariationsRequest",
  fetchSearchAccountsOnNameVariationsSuccess = "compliance/fetchSearchAccountsOnNameVariationsSuccess",
  fetchSearchAccountsOnNameVariationsFailed = "compliance/fetchSearchAccountsOnNameVariationsFailed",
  // AllAccountSearchByPhone
  fetchComplianceAccountsByPhoneRequest = "compliance/fetchComplianceAccountsByPhoneRequest",
  fetchComplianceAccountsByPhoneSuccess = "compliance/fetchComplianceAccountsByPhoneSuccess",
  fetchComplianceAccountsByPhoneFailed = "compliance/fetchComplianceAccountsByPhoneFailed",
  // RithmicAccounts
  fetchRithmicAccountsRequest = "compliance/fetchRithmicAccountsRequest",
  fetchRithmicAccountsSuccess = "compliance/fetchRithmicAccountsSuccess",
  fetchRithmicAccountsFailed = "compliance/fetchRithmicAccountsFailed",
  // WatchlistSubmissionToProp
  fetchWatchlistSubmissionToPropRequest = "compliance/fetchWatchlistSubmissionToPropRequest",
  fetchWatchlistSubmissionToPropSuccess = "compliance/fetchWatchlistSubmissionToPropSuccess",
  fetchWatchlistSubmissionToPropFailed = "compliance/fetchWatchlistSubmissionToPropFailed",
  // WatchlistWithdrawals
  fetchWatchlistWithdrawalsRequest = "compliance/fetchWatchlistWithdrawalsRequest",
  fetchWatchlistWithdrawalsSuccess = "compliance/fetchWatchlistWithdrawalsSuccess",
  fetchWatchlistWithdrawalsFailed = "compliance/fetchWatchlistWithdrawalsFailed",
  // WatchlistFailed
  fetchWatchlistFailedRequest = "compliance/fetchWatchlistFailedRequest",
  fetchWatchlistFailedSuccess = "compliance/fetchWatchlistFailedSuccess",
  fetchWatchlistFailedFailed = "compliance/fetchWatchlistFailedFailed",
  // WatchlistLiveSimTif
  fetchWatchlistLiveSimTifRequest = "compliance/fetchWatchlistLiveSimTifRequest",
  fetchWatchlistLiveSimTifSuccess = "compliance/fetchWatchlistLiveSimTifSuccess",
  fetchWatchlistLiveSimTifFailed = "compliance/fetchWatchlistLiveSimTifFailed",
  // WatchlistLiveTif
  fetchWatchlistLiveTifRequest = "compliance/fetchWatchlistLiveTifRequest",
  fetchWatchlistLiveTifSuccess = "compliance/fetchWatchlistLiveTifSuccess",
  fetchWatchlistLiveTifFailed = "compliance/fetchWatchlistLiveTifFailed",
  // WatchlistAppiusLiveTif
  fetchWatchlistAppiusLiveTifRequest = "compliance/fetchWatchlistAppiusLiveTifRequest",
  fetchWatchlistAppiusLiveTifSuccess = "compliance/fetchWatchlistAppiusLiveTifSuccess",
  fetchWatchlistAppiusLiveTifFailed = "compliance/fetchWatchlistAppiusLiveTifFailed",
  // WatchlistNotes
  fetchWatchlistNotesRequest = "compliance/fetchWatchlistNotesRequest",
  fetchWatchlistNotesSuccess = "compliance/fetchWatchlistNotesSuccess",
  fetchWatchlistNotesFailed = "compliance/fetchWatchlistNotesFailed",
  // HeliosAccountsInvestigation
  fetchHeliosAccountsInvestigationRequest = "compliance/fetchHeliosAccountsInvestigationRequest",
  fetchHeliosAccountsInvestigationSuccess = "compliance/fetchHeliosAccountsInvestigationSuccess",
  fetchHeliosAccountsInvestigationFailed = "compliance/fetchHeliosAccountsInvestigationFailed",
  // PnLCounter
  fetchPnLCounterRequest = "compliance/fetchPnLCounterRequest",
  fetchPnLCounterSuccess = "compliance/fetchPnLCounterSuccess",
  fetchPnLCounterFailed = "compliance/fetchPnLCounterFailed",
  // HeliosAndAppiusLiveTif
  fetchWatchlistHeliosAndAppiusLiveTifRequest = "compliance/fetchWatchlistHeliosAndAppiusLiveTifRequest",
  fetchWatchlistHeliosAndAppiusLiveTifSuccess = "compliance/fetchWatchlistHeliosAndAppiusLiveTifSuccess",
  fetchWatchlistHeliosAndAppiusLiveTifFailed = "compliance/fetchWatchlistHeliosAndAppiusLiveTifFailed",
  // TopLiveSimTakers
  fetchLiveSimTakersRequest = "compliance/fetchLiveSimTakersRequest",
  fetchLiveSimTakersSuccess = "compliance/fetchLiveSimTakersSuccess",
  fetchLiveSimTakersFailed = "compliance/fetchLiveSimTakersFailed",
  // TopLiveLosers
  fetchTopLiveLosersRequest = "compliance/fetchTopLiveLosersRequest",
  fetchTopLiveLosersSuccess = "compliance/fetchTopLiveLosersSuccess",
  fetchTopLiveLosersFailed = "compliance/fetchTopLiveLosersFailed",
}

export interface IComplianceSearchAccountsState {
  accounts_table: {
    data: IGetAllEntriesResponse["data"]["accounts_table"];
    loading: boolean;
  };
  logins: {
    data: IGetCustomersLoginsResponse["data"]["logins"];
    loading: boolean;
  };
  uniqueReqIp: {
    data: IUniqueReqIpResponse["data"]["usageData"];
    loading: boolean;
  };
  paymentCards: {
    data: IGetPaymentCardsResponse["data"]["paymentCards"];
    loading: boolean;
  };
  plans: {
    data: IGetAccountPlansDataResponse["data"];
    loading: boolean;
  };
  plansStatus: {
    data: IGetAccountPlansStatusResponse["data"]["accounts"];
    loading: boolean;
  };
  purchasesHistory: {
    data: TGetAccountsPurchasesResponse["data"]["purchasesHistory"];
    loading: boolean;
  };
  resetsRebillsNewPurchases: {
    data: IGetAccountsResetsRebillsNewPurchasesResponse["data"]["accounts"];
    loading: boolean;
  };
  basicAccountDetailsAndSalesValue: {
    data: IBasicAccountDetailsAndSalesValueResponse["data"]["accounts"];
    loading: boolean;
  };
  searchOnNameVariations: {
    data: IGetAllEntriesRequestByValuesResponse["data"];
    loading: boolean;
  };
  searchOnPhoneVariations: {
    data: IGetAllEntriesRequestByValuesResponse["data"];
    loading: boolean;
  };
  rithmicAccounts: {
    data: IGetEvaluationsRithmicAccountsResponse["data"];
    loading: boolean;
  };
  watchlistSubmissionToProp: {
    data: IGetPassesResponse["data"];
    loading: boolean;
  };
  watchlistWithdrawals: {
    loading: boolean;
    data: IWithdrawalsResponse["data"];
  };
  watchlistFailed: {
    loading: boolean;
    data: IFailedResponse["data"];
  };
  watchlistLiveSimTif: {
    loading: boolean;
    data: IGetWatchlistLiveSimTifResponse["data"];
  };
  watchlistLiveTif: {
    loading: boolean;
    data: IGetWatchlistLiveTifResponse["data"];
  };
  watchlistAppiusLiveTif: {
    loading: boolean;
    data: IGetWatchlistAppiusLiveTifResponse["data"];
  };
  watchlistNotes: {
    loading: boolean;
    data: IGetSearchNotesResponse["data"];
  };
  heliosAccountsInvestigation: {
    loading: boolean;
    data: IGetHeliosRelatedSearchResponse["data"];
  };
  pnlCounter: {
    loading: boolean;
    data: IGetPnlDataResponse["data"];
  };
  heliosAndAppiusLiveTif: {
    loading: boolean;
    data: IGetWatchlistHeliosAppiusLiveTifResponse["data"];
  };
  topLiveSimTakers: {
    loading: boolean;
    data: {
      liveSimTakers: ILiveSimTakers[];
      updated: { timestamp: string };
    };
  };
  topLiveLosers: {
    loading: boolean;
    data: {
      liveLosers: Array<{ name: string; amount: number }>;
      updated: { timestamp: string };
    };
  };
}
