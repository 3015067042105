import React from "react";
import { Grid } from "@material-ui/core";

import { StatusList } from "../Customer/Customer";
import useGeneralStyles from "../../useGeneralStyles";
import useStyles from "./useStyles";

import { APIBilling, APIStripe } from "../../api";
import {
  NetSalesCalculator,
  RebillStatistic,
  ChargesOverTime,
  ReportChartWithDateSelect,
  ProductsStatistic,
  SalesBreakdownPerCountry,
  SalesPerCountryLineChart,
} from "./components";
import { TimeNow } from "../../components/ui";

const Financials = (): React.ReactElement => {
  const generalClasses = useGeneralStyles();
  const classes = useStyles();

  return (
    <div className={generalClasses.containerBg}>
      <div>
        <span>Based on Chicago time:</span> <TimeNow format="MM-DD-YYYY HH:mm" />
      </div>

      <ChargesOverTime />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <SalesPerCountryLineChart />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <SalesBreakdownPerCountry />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={6}>
          <h2>Sum of all payments</h2>
          <div>
            <ReportChartWithDateSelect
              getReportRequest={APIBilling.getReportRequest}
              type="sum"
              purchaseStatus="accepted"
            />
          </div>
        </Grid>

        <Grid item xs={6}>
          <h2>Plans purchased</h2>
          <div>
            <ReportChartWithDateSelect
              getReportRequest={APIBilling.getReportRequest}
              isShowList
              type="purchased"
              purchaseStatus="accepted"
            />
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={6}>
          <h2>Refunds</h2>
          <div>
            <ReportChartWithDateSelect
              getReportRequest={APIStripe.getAllRefundsRequest}
              type="refunded"
              purchaseStatus={StatusList.refunded}
            />
          </div>
        </Grid>
        <Grid item xs={6}>
          <h2>Chargebacks</h2>
          <div>
            <ReportChartWithDateSelect
              getReportRequest={APIStripe.getAllChargebacks}
              type="chargeback"
              purchaseStatus={StatusList.chargeback}
            />
          </div>
        </Grid>
      </Grid>

      <NetSalesCalculator />

      <h2 className={classes.statisticsTitle}>Product statistics</h2>
      <ProductsStatistic />

      <RebillStatistic />
    </div>
  );
};

export default Financials;
